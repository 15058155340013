import React from 'react';

import { ReactComponent as WalkspanLogo } from '../assets/walkspan-logo-2.svg';

const ApiKeyStatus = ({ navigate }) => {

  return (
    <div className='absolute h-full w-full z-20 text-black flex flex-col gap-4 items-center justify-center bg-white p-4'>
      <WalkspanLogo className='h-[150px] w-auto'/>
      <h3 className='text-4xl'>Your trial period has ended.</h3>
      <div className='2xs:text-md md:text-2xl'>To continue using our platform, please contact us!</div>

      <div className="text-center">
        <p className='2xs:text-md md:text-xl'>Contact us at:</p>
        <p className="2xs:text-sm md:text-lg font-semibold">Info@walkspan.com</p>
        <p className="2xs:text-sm md:text-lg font-semibold">914-419-0667</p>
      </div>

      <div className='cursor-pointer text-white bg-[#f15832] py-2 px-8 mt-6' onClick={() => navigate('/home')}>
        Home
      </div>
    </div>
  );
};

export default ApiKeyStatus;