import React, { useContext } from "react";
import { StoreContext } from "../../store";

import ServiceCharacter from '../../assets/landing-page/service-gem.svg';
import ServiceGem from '../../assets/landing-page/service-character.svg';
import './index.css';

import { scrollToTop } from "../../services";


const TwentyMinuteCity = () => {
  const { navigate } = useContext(StoreContext);

  const planningSteps = [
    {
      number: "01",
      title: "Count what counts",
      description:
        "Inventories of the natural, built, economic, and socio-cultural environment via computer vision and advanced data gathering techniques.",
      section: "white",
    },
    {
      number: "02",
      title: "Perception Survey",
      description:
        "Identify the cognitive, affective, physical, and experiential perceptions of residents about their community.",
      section: "white",
    },
    {
      number: "03",
      title: "Aspirations Survey",
      description:
        "Determine resident unique aspirations and sense of place.",
      section: "white",
    },
    {
      number: "04",
      title: "Placemaking",
      description:
        "Alternatives analyses reflecting community aspirations, sense of place, sustainability, and technology solutions.",
      section: "white",
    },
    {
      number: "05",
      title: "Sustainable Design",
      description: "Dynamic and incremental design and growth.",
      section: "black",
    },
    {
      number: "06",
      title: "Impacts Assessment",
      description:
        "Measure the positive economic, societal, infrastructure, and natural impacts.",
      section: "black",
    },
    {
      number: "07",
      title: "Development",
      description:
        "Stakeholder involvement, regulation, planning, mitigation, staging, and building.",
      section: "black",
    },
    {
      number: "08",
      title: "Operations",
      description: "Develop operations, monitoring, risk, and contingency plans.",
      section: "black",
    },
  ];

  const gotoContactUs = () => {
    scrollToTop();
    navigate('/contact-us');
    document.title = 'Contact Us - Walkspan';
  };

  return (
    <div className="bg-gray-100">
      <div className='bg-[#211F1F]'>
        <div className="text-white pt-16 text-center">
            <p className="text-lg max-w-screen-md mx-auto">
            A one-mile radius or a one square-mile urban area where the experience of walking and other soft mobility transportation modes are heightened by the availability of <span className="font-bold">lifestyle essentials</span> and the <span className="font-bold">quality</span> of the public realm.
            </p>
        </div>

        <div className="py-12 text-white mt-4">
            <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
            <div className="text-center">
                <h2 className="mb-4 text-lg font-medium">NEIGHBORHOOD GEMS</h2>
                <img
                src={ServiceGem}
                alt="Neighborhood Gems"
                className="w-full"
                />
            </div>
            <div className="text-center">
                <h2 className="mb-4 text-lg font-medium">NEIGHBORHOOD CHARACTER</h2>
                <img
                src={ServiceCharacter}
                alt="Neighborhood Character"
                className="w-full"
                />
            </div>
            </div>
            <div className="text-center mt-8">
            <button className="landing-view-btn" onClick={() => gotoContactUs()}>
                Request a Demo
            </button>
            </div>
        </div>
      </div>

      {/* Planning Sections */}
      <div className="py-16 bg-white text-center">
        <h2 className="text-2xl md:text-3xl font-medium mb-8">PLANNING THE 20-MINUTE CITY</h2>
        <div className="max-w-3xl mx-auto flex flex-col gap-8 px-4">
          {planningSteps
            .filter((step) => step.section === "white")
            .map((step, index) => (
            <div
                key={index}
                className={`flex flex-col md:flex-row items-center ${
                index % 2 === 0 ? "md:justify-start" : "md:justify-end"
                }`}
            >
                {/* Content Section */}
                <div
                    className={`flex flex-col md:w-1/2 ${
                        index % 2 === 0 ? "items-start text-left" : "items-end text-right"
                    }`}
                    >
                    <h3 className="text-6xl text-gray-300 font-bold">{step.number}</h3>
                    <h4 className="text-3xl font-bold">{step.title}</h4>
                    <p className="text-gray-600 mt-2">{step.description}</p>
                </div>
            </div>
            ))}
        </div>
      </div>
      <div className="py-16 bg-black text-white text-center planning-gradient">
        <div className="max-w-3xl mx-auto flex flex-col gap-8 px-4">
            {planningSteps
            .filter((step) => step.section === "black")
            .map((step, index) => (
                <div
                key={index}
                className={`flex flex-col md:flex-row items-center ${
                    index % 2 === 0 ? "md:justify-start" : "md:justify-end"
                }`}
                >
                {/* Content Section */}
                <div
                    className={`flex flex-col md:w-1/2 ${
                    index % 2 === 0
                        ? "items-start text-left"
                        : "items-end text-right"
                    }`}
                >
                    <h3 className="text-6xl text-[#787878] font-bold">{step.number}</h3>
                    <h4 className="text-3xl font-bold">{step.title}</h4>
                    <p className="text-gray-300 mt-2">{step.description}</p>
                </div>
                </div>
            ))}
          </div>
        </div>
    </div>
  );
};

export default TwentyMinuteCity;
