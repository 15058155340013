import React, { useContext } from "react";
import { StoreContext } from "../../store";

import NSD from '../../assets/landing-page/Tab/tab1.png';
import NGMap from '../../assets/landing-page/Tab/tab4.svg';
import NGGem1 from '../../assets/landing-page/real-estate/ng-gem1.png';
import NGGem2 from '../../assets/landing-page/real-estate/ng-gem2.png';
import NGGem3 from '../../assets/landing-page/real-estate/ng-gem3.png';
import NCMap from '../../assets/landing-page/Tab/tab3.svg';
import NCFeel1 from '../../assets/landing-page/real-estate/nc-feel1.png';
import NCFeel2 from '../../assets/landing-page/real-estate/nc-feel2.png';
import NCFeel3 from '../../assets/landing-page/real-estate/nc-feel3.png';
import WRIcon1 from '../../assets/landing-page/real-estate/walking-radius1.png';
import WRIcon2 from '../../assets/landing-page/real-estate/walking-radius2.png';
import WRIcon3 from '../../assets/landing-page/real-estate/walking-radius3.png';
import Comparison from '../../assets/landing-page/Tab/tab5.svg';

// other features
import QuickSearch from '../../assets/quick-search.svg';
import Rating from '../../assets/landing-page/real-estate/other-features/rating.png';
import Carbon from '../../assets/landing-page/real-estate/other-features/carbon-f.png';
import Health from '../../assets/landing-page/real-estate/other-features/health.png';
import Navigation from '../../assets/landing-page/real-estate/other-features/navigation.png';
import Filter from '../../assets/landing-page/real-estate/other-features/filter.png';
import WhiteLable from '../../assets/landing-page/real-estate/other-features/white-label.png';
import Custom from '../../assets/landing-page/real-estate/other-features/custom.png';

import { scrollToTop } from "../../services";

const RealEstate = () => {
  const { navigate } = useContext(StoreContext);

  const scores = [
    {
      id: 1,
      label: "None",
      description: "Neighborhood Gems",
      image: NGGem1,
    },
    {
      id: 2,
      label: "Some",
      description: "Neighborhood Gems",
      image: NGGem2,
    },
    {
      id: 3,
      label: "Abundant",
      description: "Neighborhood Gems",
      image: NGGem3,
    },
    {
      id: 1,
      label: "No features",
      description: "Neighborhood Character",
      image: NCFeel1,
    },
    {
      id: 2,
      label: "Some features",
      description: "Neighborhood Character",
      image: NCFeel2,
    },
    {
      id: 3,
      label: "Abundant features",
      description: "Neighborhood Character",
      image: NCFeel3,
    },
  ];

  const walkingRadius = [
    {
      distance: "0.25 mi",
      time: "5 min walk",
      image: WRIcon1,
      className: "w-16 h-16"
    },
    {
      distance: "0.50 mi",
      time: "10 min walk",
      image: WRIcon2,
      className: "w-24 h-24"
    },
    {
      distance: "1.0 mi",
      time: "20 min walk",
      image: WRIcon3,
      className: "w-40 h-40"
    },
  ];

  const features = [
    {
      id: 1,
      title: "Quick Search",
      description: "Advanced search functionality",
      icon: QuickSearch,
      className: '2xs:h-8 md:h-12 w-auto m-auto'
    },
    {
      id: 2,
      title: "Rating",
      description: "1 to 5 stars",
      icon: Rating,
      className: 'h-6 w-auto m-auto'
    },
    {
      id: 3,
      title: "Carbon Footprint",
      description: "CO2 saved",
      icon: Carbon,
      className: 'h-24 w-auto m-auto'
    },
    {
      id: 4,
      title: "Health",
      description: "calories, steps, distance",
      icon: Health,
      className: '2xs:h-16 md:h-24 w-auto m-auto'
    },
    {
      id: 5,
      title: "Navigation",
      description: "short distance",
      icon: Navigation,
      className: 'h-24 w-auto m-auto'
    },
    {
      id: 6,
      title: "Filters",
      description: "lifestyle essentials",
      icon: Filter,
      className: 'h-10 w-auto m-auto'
    },
    {
      id: 7,
      title: "White Label",
      description: "white label format",
      icon: WhiteLable,
      className: 'h-20 w-auto m-auto'
    },
    {
      id: 8,
      title: "Customization",
      description: "bespoke custom design",
      icon: Custom,
      className: 'h-12 w-auto m-auto'
    },
  ];

  const otherFeatures = () => {
    return (
      <div className="bg-black py-16">
        <h2 className="text-white text-center 2xs:text-xl md:text-3xl font-medium 2xs:mb-6 md:mb-12">
          OTHER FEATURES
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 2xs:gap-4 md:gap-8 px-4">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="flex flex-col items-center bg-[#0D0D0D] p-6 text-white text-center shadow-lg aspect-square 2xs:w-[220px] md:w-[100%] m-auto"
            >
              <img
                src={feature.icon}
                alt={feature.title}
                className={feature.className}
              />
              <h3 className="text-lg md:text-2xl font-medium">{feature.title}</h3>
              <p className="text-xs md:text-lg text-gray-500 m-0 mt-4 mb-10">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const gotoContactUs = () => {
    scrollToTop();
    navigate('/contact-us');
    document.title = 'Contact Us - Walkspan';
  };

  return (
    <div className="bg-gray-100">
      <div className="bg-[#211F1F] px-4 lg:px-0">
        <div className="text-white pt-16 text-center">
          <h1 className="text-2xl md:text-3xl font-medium uppercase">Walkspan celebrates the power of place</h1>
          <p className="2xs:text-sm md:text-lg mt-6 max-w-2xl mx-auto">
            Our <b>AI-driven location intelligence</b> platform is designed to seamlessly complement the real property search engine. 
            It shows home seekers the neighborhood they desire. The platform operationalizes neighborhood search through its patent issued single interface, scoring, mapping, reporting and recommendation system. 
          </p>
        </div>
        <div className="py-8 text-white mt-8">
          <div className="max-w-6xl mx-auto grid grid-cols-1 gap-8 px-4">
            <div className="text-center">
              <h2 className="mb-8 text-2xl md:text-3xl font-medium">NEIGHBORHOOD SUMMARY DASHBOARD</h2>
              <img
                src={NSD}
                alt="Neighborhood Gems"
                className="w-full"
              />
            </div>
          </div>
          <div className="text-center my-4">
            <button className="landing-view-btn" onClick={() => gotoContactUs()}>
              Request a Demo
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-6xl mx-auto grid grid-cols-1 justify-center gap-y-12 px-4 py-12 text-center">
          <h2 className="text-2xl font-medium mb-6 uppercase">MAPS AND SCORE</h2>
          <div className="2xs:flex 2xs:flex-col-reverse md:grid grid-cols-1 md:grid-cols-12 gap-8 2xs:p-0 md:px-4">
            <div className="2xs:col-span-12 md:col-span-8">
              <img
                src={NGMap}
                alt="Neighborhood Gems Map"
                className="w-full h-auto"
              />
            </div>

            <div className="2xs:col-span-12 md:col-span-4">
              <h2 className="text-3xl font-bold mb-6 2xs:text-center md:text-left">Neighborhood Gems</h2>
              <div className="flex flex-col gap-4 items-center 2xs:justify-center md:justify-left 2xs:items-start">
                {scores
                  .filter((score) => score.description === "Neighborhood Gems")
                  .map((score) => (
                  <div
                    key={score.id}
                    className="flex justify-start gap-4 items-center text-center"
                  >
                    <img
                      src={score.image}
                      alt={score.label}
                      className="w-auto h-16"
                    />
                    <span className="font-medium text-lg -mt-2">{score.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8 2xs:p-0 md:px-4">
            <div className="2xs:col-span-12 md:col-span-4">
              <h2 className="text-3xl font-bold mb-6 2xs:text-center md:text-left">Neighborhood Character</h2>
              <div className="flex flex-col gap-4 items-end">
                {scores
                  .filter((score) => score.description === "Neighborhood Character")
                  .map((score) => (
                    <div
                      key={score.id}
                      className="flex flex-row-reverse gap-4 justify-end items-center text-center"
                    >
                      <img
                        src={score.image}
                        alt={score.label}
                        className="w-auto h-16"
                      />
                      <span className="font-medium text-lg">{score.label}</span>
                    </div>
                ))}
              </div>
            </div>
            <div className="2xs:col-span-12 md:col-span-8">
              <img
                src={NCMap}
                alt="Neighborhood Gems Map"
                className="w-full shadow-lg"
              />
            </div>
          </div>
        </div>
        <div className="bg-[#211F1F] text-center mt-12 text-white py-12">
          <h2 className="text-2xl font-medium mb-6 uppercase">Walking Radius</h2>
          <div className="flex 2xs:flex-col md:flex-row justify-center gap-8">
            {walkingRadius.map((radius, index) => (
              <div
                key={index}
                className="flex 2xs:flex-col md:flex-row items-center text-center gap-2"
              >
                <div className="flex flex-col 2xs:text-center md:text-right mr-2">
                  <span className="text-lg mt-2 font-bold">{radius.distance}</span>
                  <span className="text-sm">{radius.time}</span>
                </div>
                <img
                  src={radius.image}
                  alt={radius.distance}
                  className={radius.className}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-y-6 text-center px-4 py-12 max-w-screen-2xl mx-auto">
          <h2 className="text-2xl font-medium uppercase">Comparison</h2>
          <span className='font-bold text-2xl'>Properties and Neighborhoods</span>
          <img
            src={Comparison}
            alt="Neighborhood Gems"
            className="w-full"
          />
        </div>
      </div>
      {otherFeatures()}

    </div>
  );
};

export default RealEstate;
