import { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { StoreContext } from '../../store';
import { ReactComponent as Walk } from '../../assets/walking-distance.svg';
import { ReactComponent as WalkWhite } from '../../assets/walking-distance-white.svg';
import { ReactComponent as HouseIcon } from '../../assets/house-icon.svg';

import Show from '../../utility/Show';

import { debounce } from '../../services';
import './WalkingDistanceButtons.css';
import { createPath } from 'react-router-dom';

export const WalkingDistanceButtons = ({
  color = null,
  bgColor,
  isDark = false,
  isTitle = true,
  showTitle = true,
}) => {
  const [selectedButton, setSelectedButton] = useState(1);
  const { state, dispatch, actions } = useContext(StoreContext);
  const location = useLocation();

  const {
    isLoading,
    currentDistance,
    showDashboard,
    currentView,
    showFilter,
    showCompareLifeStyle,
    showWalkIndexCompare,
    quickSearchMarkers,
    isPlugin,
  } = state;

  const isModern = currentView === 'Modern';
  const titleIcon = color ? <Walk /> : <WalkWhite />;

  const distanceData = ['0.25 mi', '0.5 mi', '1 mi'];
  const timeData = ['5 min', '10 min', '20 min'];

  const onButtonClick = (distance, value) => {
    if (!isLoading) {
      actions.sendDataToGoogleAnalytics(
        'distance_btn_clicks',
        {
          distance: distance,
        }
      );
      debounce(
        dispatch({ type: 'SET_CURRENT_DISTANCE', payload: value.toString() }),
        3000
      );
      if(!showDashboard){
        actions.setMapCenter();
      }
      // setSelectedButton(value);
    }
  };

  // const renderDasboardButtons = () => {
  //   return (
  //     <div className='dasboard-buttons-container'>
  //       {distanceData.map((distance, index) => {
  //         return (
  //           <button
  //             className={`dashboard-walking-distance-button
  //               ${currentDistance == index + 1 && 'selected-dashboard-walking-distance-button'}
  //               ${isLoading && 'btn-loading'}
  //             `}
  //             onClick={() => onButtonClick(index + 1)}
  //           >
  //             <span className='dashboard-walking-distance-text'>
  //               {index + 1 !== distanceData.length && '0'}{distance}
  //             </span>
  //           </button>
  //         );
  //       })}
  //     </div>
  //   )
  // }

  const loading = showDashboard
    ? (isLoading || quickSearchMarkers.isLoading)
    : location.pathname.startsWith('/address/')
    ? isLoading
    : (isLoading || actions.areMapsLoading());

  const renderButtons = () => {
    return (
      <div className='buttons-container'>
        {distanceData.map((distance, index) => {
          return (
            <button
              className={`walking-distance-button ${
                isDark && 'dark-walking-distance-button'
                } ${currentDistance == index + 1 && 'selected-button'
                } ${(currentDistance == index + 1 && isDark) && 'dark-selected-button'
                } ${isModern &&
                  !showWalkIndexCompare &&
                  'modern-walking-distance-button'
                } ${isModern &&
                  currentDistance == index + 1 &&
                  !showWalkIndexCompare &&
                  'modern-selected-button'
                } ${loading && 'btn-loading'
              }`}
              style={actions.getBrandingColor()}
              onClick={
                loading ? null : () => onButtonClick(distance, index + 1)
              }
              key={`walking-distance-button-${index}`}
            >
              <span className='walking-distance'>{distance}</span>
              <span className='walking-time'>{timeData[index]}</span>
            </button>
          );
        })}
      </div>
    )
  };

  const renderDasboardBtns = () => {
    return (
      <div className='dashboard-wd-buttons-wrapper'>
          {distanceData.map((distance, index) => {
            return (
              <div className='dashboard-wd-container'>
                <span className={`walking-distance text-xs ${
                    currentDistance == index + 1 ? 'selected' : ''
                  }`}
                >
                  {timeData[index]}
                </span>
                <button
                  className={`dashboard-wd-button ${
                    currentDistance == index + 1 ? 'selected' : ''
                  } ${
                    isLoading && 'btn-loading'
                  }`}
                  style={actions.getBrandingColor()}
                  onClick={
                    loading ? null : () => onButtonClick(distance, index + 1)
                  }
                  key={`walking-distance-button-${index}`}
                >
                  <div className={`icon-wrapper ${
                        index === 0 ? 'small' : ''
                      } ${
                        index === 1 ? 'medium' : ''
                      } ${
                        index === 2 ? 'large' : ''
                      }`}
                    >
                    <div class="line"></div>
                    <HouseIcon />
                    <div class="outer-circle"></div>
                  </div>
                  {/* <span className='walking-distance'>{distance}</span> */}
                  {/* <span className='walking-time'>{timeData[index]}</span> */}
                </button>
                <span className={`walking-distance text-xs ${
                    currentDistance == index + 1 ? 'selected' : ''
                  }`}
                >
                  {distance}
                </span>
              </div>
            );
          })}
      </div>
    )
  }

  const getColor = () => {
    if (color && branding === null) {
      return color;
    }
    else if(color === null) {
      return '#fff';
    } else {
      return actions.getBrandingColor();
    }
  }

  const branding = actions.getBrandingColor();

  const getTitleColor = () => {
    const style = {
      '--title-color': isDark ? '#ffffff' :
      (branding ? branding['--color'] : color),
    };
    return style;
  };

  return (
    <div>
      <div
        id='walking-distance-container'
        className={`walking-distance-container ${
          isModern &&
          !showWalkIndexCompare &&
          'modern-walking-distance-container'
        } ${
          isPlugin ? 'walking-distance-container-plugin' : ''
        }`}
      >
        <Show.When isTrue={showTitle}>
          <Show>
            <Show.When isTrue={isTitle && (!showDashboard || showWalkIndexCompare)}>
              <div
                  className='sidebar-title'
                  style={getTitleColor()}
                >
                  {titleIcon}
                  <h5>
                    WALKING DISTANCE
                  </h5>
                </div>
            </Show.When>

            <Show.Else>
              <Show.When isTrue={isModern}>
                WALKING DISTANCE
              </Show.When>
            </Show.Else>
          </Show>
        </Show.When>
        <Show>
          <Show.When isTrue={showDashboard}>
            {/* {renderButtons()} */}
            {renderDasboardBtns()}
          </Show.When>
          <Show.Else>
            {renderButtons()}
          </Show.Else>
        </Show>
      </div>
    </div>
  );
};
