import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { StoreContext } from '../../store';

import './index.css';

import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as CircleIcon } from '../../assets/address-score/circle.svg';
import { ReactComponent as Gem } from '../../assets/address-score/gem.svg';
import { ReactComponent as Favorite } from '../../assets/favorite-outline.svg';
import { ReactComponent as Rating } from '../../assets/rating-bar.svg';
import Placeholder from '../../assets/address-score/placeholder.png';

import {
  getLifeStyleWording,
  getWalkIndexWording,
  getLimit,
  getLifeStyleScore,
  saveAddressToFavorites,
  saveAddressToRecentlyViewed,
  isObjectEmpty,
} from '../../services';

import { fetchPlaceDetails } from '../../api';

import Map from './map';
import Show from '../../utility/Show';
import Skeleton from '../Skeleton';

import neighborhoodDescription from "../../data/neigborhood-description.json";

const GetScore = ({ fetchAddressData }) => {
  const { state, actions, dispatch } = useContext(StoreContext);
  const {
    currentAddress,
    currentDistance,
    walkindexScores,
    lifeStyleScores,
    isStandalone,
    scoreProperties,
    savedAddresses,
    isGoogleMapsLoaded,
    currentView,
    isLoading,
    isMapLoading,
    token,
    currentCity,
  } = state;
  const [predictions, setPredictions] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const inputRef = useRef();
  const [loadingScores, setLoadingScores] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const fetchLifeStyleScores = async (payload, signal) => {
    try {
      await actions.fetchLifeStyleScores(payload, 0, signal);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
      throw error;
    }
  };

  const fetchWalkIndexRatings = async (payload, signal) => {
    try {
      await actions.fetchWalkIndexRatings(payload, 0, signal);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
      throw error;
    }
  };

  useEffect(() => {
    if (!currentAddress) return;

    if (currentAddress) {
      const controller = new AbortController();
      const signal = controller.signal;
      const payload = { latitude: currentAddress.latitude, longitude: currentAddress.longitude };

      const fetchData = async () => {
        try {
          setLoadingScores(true);
          await Promise.all([
            fetchLifeStyleScores(payload, signal),
            fetchWalkIndexRatings(payload, signal)
          ]);
        } catch (error) {
          console.log(error)
        } finally {
          setLoadingScores(false);
        }
      };

      fetchData();

      return () => {
        controller.abort();
      };
    }
  }, [currentAddress]);

  useEffect(() =>{
    if (!currentAddress) return;

    const controller = new AbortController();
    const signal = controller.signal;
    const payload = { latitude: currentAddress.latitude, longitude: currentAddress.longitude };

    const fetchData = async () => {
      try {
        setLoadingScores(true);
        await Promise.all([
          fetchLifeStyleScores(payload, signal),
          fetchWalkIndexRatings(payload, signal)
        ]);
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingScores(false);
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [currentDistance]);

  const isAddressSaved = () => {
    return savedAddresses?.some(
      (address) => address?.property === currentAddress?.property
    );
  };

  const handleSaveAddressToFavorites = () => {
    saveAddressToFavorites(
      isAddressSaved() ? 'remove' : 'save',
      currentAddress,
      savedAddresses,
      dispatch,
      enqueueSnackbar,
    );
  };

  const lifeStyleEssentialScores = () => {
    if (!lifeStyleScores[0] || !isMounted) return {};

    const categories = ['Food', 'Services', 'Shops', 'Transit', 'Leisure'];
    const essentialScores = {};

    categories.forEach((category) => {
      const type = category === 'Leisure' ? 'leisure' : category.toLowerCase();
      essentialScores[category] = getLifeStyleScore(
        lifeStyleScores[0][type],
        currentDistance,
        isStandalone
      );
    });

    return essentialScores;
  };

  const getOverallGemScore = () => {
    if (isObjectEmpty(lifeStyleScores?.[0]) || !lifeStyleScores[0]) return null;
    const scores = Object.values(lifeStyleEssentialScores());

    const counts = [0, 0, 0];
    scores.forEach((score) => {
      const roundedScore = Math.round(score);
      counts[roundedScore - 1]++;
    });

    if (counts[2] >= 1) return 3;
    if (counts[1] >= 1) return 2;
    return 1;
  }

  const getOverallFeelScore = () => {
    if (isObjectEmpty(walkindexScores?.[0]) || !walkindexScores?.[0]) return null;
    const { activities, beautyM, beautyN, comfort, noise } = walkindexScores?.[0];

    const scores = [
      parseFloat(activities),
      parseFloat(beautyM),
      parseFloat(beautyN),
      parseFloat(comfort),
      parseFloat(noise),
    ];

    const counts = [0, 0, 0];
    scores.forEach((score) => {
      const roundedScore = Math.round(score);
      counts[roundedScore - 1]++;
    });

    if (counts[2] >= 1) return 3;
    if (counts[1] >= 1) return 2;
    return 1;
  };

  const getWalkableDescription = (score, isWalkIndex) => {
    const limit = getLimit(currentDistance);

    if (score === 1) {
      return isWalkIndex
        ? 'Scarce presence of features <25%'
        : `Less than ${limit[0]} living essentials`;
    } else if (score === 2) {
      return isWalkIndex
        ? 'Moderate presence of features >25% <50%'
        : `${limit[0]} -  ${limit[1]} living essentials`;
    } else {
      return isWalkIndex
        ? 'High presence of features >50%'
        : `> ${limit[1]} living essentials`;
    }
  };

  const searchAddress = () => {
    if (isGoogleMapsLoaded) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      if (autocompleteService && inputRef.current) {
        const inputText = inputRef.current.value;
        const city = currentCity ? `${currentCity}, ` : '';

        autocompleteService.getPlacePredictions(
          {
            input: city + inputText,
            types: ['geocode'],
            componentRestrictions: { country: "us" }
          },
          async (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
              try {
                // Fetch details for each prediction
                const detailedPredictions = await Promise.all(predictions.map(async (prediction) => {
                  try {
                    const details = await fetchPlaceDetails(prediction.place_id);
                    return {
                      ...prediction,
                      latitude: details.geometry.location.lat(),
                      longitude: details.geometry.location.lng(),
                    };
                  } catch (error) {
                    console.error(`Failed to fetch details for place_id ${prediction.place_id}:`, error);
                    return null;
                  }
                }));

                // Filter out any null results (in case some place details couldn't be fetched)
                const validPredictions = detailedPredictions.filter(prediction => prediction !== null);
                setPredictions(validPredictions);
              } catch (error) {
                console.error('Error fetching place details:', error);
                setPredictions([]);
              }
            } else {
              setPredictions([]);
            }
          }
        );
      }
    }
  };

  const replaceWithDashes = (str) => {
    // Replace non-alphanumeric characters with dashes
    let result = str.replace(/[^a-zA-Z0-9]/g, '-');
    // Replace multiple consecutive dashes with a single dash
    result = result.replace(/-+/g, '-');
    // Remove leading and trailing dashes if any
    result = result.replace(/^-|-$/g, '');
    return result;
  };

  const addressClick = async (address) => {
    // dispatch({ type: 'SET_LOADING', payload: true });
    // setPredictions([]);
    // inputRef.current.value = '';
    // const data = {
    //   property: address.description,
    //   latitude: address.latitude,
    //   longitude: address.longitude
    // }
    // saveAddressToRecentlyViewed(data, recentlyViewedAddresses, dispatch);
    // await actions.fetchAddressData(address.description);
    const newAddress = `address/${replaceWithDashes(address.description)}`;
    const baseUrl = window.location.origin;
    window.location.replace(`${baseUrl}/${newAddress}`);
  };

  const handleSearchChange = (event) => {
    if (!event.target.value) {
      setPredictions([]);
    } else {
      searchAddress();
    }
  };

  const openScoreGuide = () => {
    dispatch({ type: 'SET_SHOW_SCORE_GUIDE', payload: true });
    dispatch({ type: 'SET_SHOW_TUTORIAL', payload: true });
  };

  const viewFullVersion = () => {
    const newAddress = `?address=${encodeURIComponent(currentAddress.property)}`;
    const baseUrl = window.location.origin;
    window.location.replace(`${baseUrl}/${newAddress}`);
  };

  const getNeighborhoodDescription = () => {
    const selectedNeighborhood = neighborhoodDescription.neighborhoods.filter((neighborhood) => {
      return (
        currentAddress?.sublocality?.toLowerCase() === neighborhood?.sublocality?.toLowerCase() &&
        currentAddress?.neighborhood?.toLowerCase() === neighborhood?.name?.toLowerCase()
      );
    });

    if (selectedNeighborhood.length > 0) {
      return selectedNeighborhood[0].description
        .split('\n')
        .map((paragraph, index) => (
          <p key={index}>
            {paragraph.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
        ));
    }
    return null;
  };

  return (
    <>
      <div className='address-score-body max-w-screen-lg'>
        <div className='score-container'>
          <div className='search-address-input'>
            <input
              ref={inputRef}
              onChange={(e) => handleSearchChange(e)}
              placeholder='Search for an address, location , cities ...'
            />
            <Search className='search-icon' />
            <Show>
              <Show.When isTrue={predictions.length !== 0}>
                <div
                  className={`prediction-container ${
                    currentView === 'Modern' && 'modern-prediction-container'
                  }`}
                >
                  {predictions.map((prediction) => (
                    <div
                      className='prediction'
                      key={prediction.place_id}
                      onClick={() => addressClick(prediction)}
                    >
                      {prediction.description}
                    </div>
                  ))}
                </div>
              </Show.When>
            </Show>
          </div>
          <div className='neighborhood-score'>
            <div className='feature-score'>
              <div className='label'>Character Score</div>
              <Show>
                <Show.When isTrue={loadingScores}>
                  <Skeleton count={1} size={75} width='105px' baseColor='#a9a9a9' />
                </Show.When>
                <Show.Else>
                  <Show>
                    <Show.When isTrue={getOverallFeelScore() && !isObjectEmpty(walkindexScores[0])}>
                      <div className='score-icon-wrapper'>
                        <div
                          className='score-icon'
                          data-score-value={getOverallFeelScore()?.toString()}
                        >
                          <CircleIcon />
                        </div>
                        <span className='value'>{getOverallFeelScore()}</span>
                      </div>
                    </Show.When>
                    <Show.Else>
                      <div className='no-data'>No Data</div>
                    </Show.Else>
                  </Show>
                </Show.Else>
              </Show>
              <Show.When isTrue={getOverallFeelScore() && !loadingScores}>
                {getWalkIndexWording(getOverallFeelScore())}
                <div className='description'>
                  {getWalkableDescription(getOverallFeelScore(), true)}
                </div>
              </Show.When>
            </div>
            <div className='feature-score'>
              <div className='label'>Gem Score</div>
              <Show>
                <Show.When isTrue={loadingScores}>
                  <Skeleton count={1} size={74} width='105px' baseColor='#a9a9a9' />
                </Show.When>
                <Show.Else>
                  <Show>
                    <Show.When isTrue={getOverallGemScore() && !isObjectEmpty(lifeStyleScores[0])}>
                      <div className='score-icon-wrapper gem'>
                        <div
                          className='score-icon gem'
                          data-score-value={getOverallGemScore()?.toString() || '1'}
                        >
                          <Gem />
                        </div>
                        <span className='value'>{getOverallGemScore() || 1}</span>
                      </div>
                    </Show.When>
                    <Show.Else>
                      <div className='no-data'>No Data</div>
                    </Show.Else>
                  </Show>
                </Show.Else>
              </Show>
              <Show.When isTrue={getOverallGemScore() && !loadingScores}>
                {getLifeStyleWording(scoreProperties[0], currentDistance)}
                <div className='description'>
                  {getWalkableDescription(getOverallGemScore(), false)}
                </div>
              </Show.When>
            </div>
          </div>
          <div className='buttons'>
            <div onClick={() => handleSaveAddressToFavorites()}>
              <Favorite className={`heart-icon ${
                isAddressSaved() && 'saved-address'}`}
              />
              {isAddressSaved() ? 'Unfavorite' : 'Favorite'}
            </div>
            <div onClick={() => openScoreGuide()}>
              <Rating className='rating-icon' /> Score Guide
            </div>
            {/* <div>
              Add to your site
            </div> */}
          </div>
          <div className='view-btn' onClick={() => viewFullVersion()}>
            View full version of our platform
          </div>
        </div>
        <Map
          state={state}
          dispatch={dispatch}
          actions={actions}
          fetchAddressData={fetchAddressData}
        />
      </div>
      <Show.When isTrue={getNeighborhoodDescription() !== null}>
        <section className='address-details-wrapper max-w-screen-lg'>
          <h3>About this neighborhood</h3>
          <div className='details'>
            {/* <img src={Placeholder} alt="address-image" /> */}
            <div>
              <div className='neighborhood'>{currentAddress?.neighborhood}</div>
              <div className='description'>
                {getNeighborhoodDescription()}
              </div>
            </div>
          </div>
        </section>
      </Show.When>
    </>
  )
};

export default GetScore;