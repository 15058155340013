import { useState, useEffect, useContext, useRef } from 'react';
import { StoreContext } from '../../store';
import './index.css';
import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';
import { ReactComponent as Info } from '../../assets/info-outline.svg';

import Show from '../../utility/Show.jsx';
import FormError from '../../utility/FormError.jsx';
import { CircularLoader } from '../Loaders/index.jsx';

const Login = () => {
  const { state, dispatch, actions, navigate } = useContext(StoreContext);
  const {
    token,
    isLoading,
    errorMessage,
    FPSubmitted,
    confirmFPSubmitted,
  } = state;
  const formRef = useRef();

  // useEffect(() => {
  //   if (token && token !== null) {
  //     navigate('/plugin');
  //   }
  // }, [token]);

  const gotoSignup = () => {
    actions.clearErrorMessage();
    navigate('/signup');
  };

  const gotoForgotPassword = () => {
    actions.clearErrorMessage();
    dispatch({
      type: 'SET_FORGOT_PASSWORD_SUBMITTED',
      payload: false
    });
    if (FPSubmitted) {
      dispatch({
        type: 'SET_FORGOT_PASSWORD_SUBMITTED',
        payload: false,
      });
    }

    if (confirmFPSubmitted) {
      dispatch({
        type: 'SET_CONFIRM_FORGOT_PASSWORD_SUBMITTED',
        payload: false,
      });
    }
    navigate('/forgot-password');
  };

  const loginSubmit = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const email = formRef.current[0].value;
      const password = formRef.current[1].value;

      if (email.trim() === '' || password.trim() === '') {
        dispatch({
          type: 'SET_ERROR_MESSAGE',
          payload: 'Email and password are required'
        });
        return;
      }

      const payload = {
        email: email,
        password: password,
      }
      await actions.handleLogin(payload);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const handleSubmit = () => {
    actions.clearErrorMessage();
    loginSubmit();
  };

  return (
    <div className="login-container">
      <div className='login-overlay'></div>
      <div className='login-card'>
        <WalkspanLogo className='walkspan-logo'/>
        <div className='title'>Login to your account</div>
        <form ref={formRef}>
          <div className="input-container">
            <label className="input-label" for="email">Email</label>
            <input
              type="text"
              className="input-field"
              id="email"
              name="email"
              placeholder="Type your email"
            />
          </div>
          <div className="input-container">
            <label className="input-label" for="password">Password</label>
            <input
              type="password"
              className="input-field"
              id="password"
              name="password"
              placeholder="Type your password"
            />
          </div>
          <Show.When isTrue={errorMessage}>
            <FormError error={errorMessage} />
          </Show.When>
          <div className='login-actions'>
            {/* <div className="input-container remember-me">
              <input type="checkbox" id="remember-me" name="remember-me" value="true" />
              <label for="remember-me"> Remember Me</label><br></br>
            </div> */}
            <div
              className='forgot-password'
              onClick={() => gotoForgotPassword()}
            >
              Forgot your password?
            </div>
          </div>
        </form>
        <div className='login-buttons'>
          <button
            className='submit-button'
            onClick={isLoading ? null : () => handleSubmit()}
          >
            <Show>
              <Show.When isTrue={isLoading}>
                <CircularLoader size={'10px'} />
              </Show.When>
              <Show.Else>
                Login
              </Show.Else>
            </Show>
          </button>
          <button
            className='signup-button'
            onClick={() => gotoSignup()}
          >
            Signup
          </button>
        </div>
      </div>
    </div>
  )
};


export default Login;