import { useState, useRef } from 'react';
import { ReactComponent as Search } from '../../assets/search.svg';
import './index.css';

import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-beta.svg';
import { ReactComponent as UserIcon } from '../../assets/user-icon.svg';
import { ReactComponent as PlayIcon } from '../../assets/play-icon.svg';
import { ReactComponent as CurrentLocation } from '../../assets/location-current.svg';
import { ReactComponent as AlertIcon } from '../../assets/alert-icon.svg';
import { ReactComponent as MenuIcon } from '../../assets/landing-page/menu.svg';

import Show from '../../utility/Show';
import LazyImage from '../../utility/LazyImage';
import { isObjectEmpty } from '../../services';
import { carouselImages, carouselPlaceholders } from '../../constants';

import Slider from 'react-slick';

export const AddressSearch = ({ dispatch, actions, state, openTutorial }) => {
  const {
    currentView,
    isGoogleMapsLoaded,
    userDetails,
    isInTrialMode,
    isMobile,
    isPlugin,
    clientName,
    currentCity,
  } = state;
  const inputRef = useRef();
  const sliderRef = useRef(null);

  const [predictions, setPredictions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isModern = currentView === 'Modern';

  const leftCarouselsettings = {
    dots: false,
    vertical: isMobile ? false : true,
    infinite: true,
    autoplay: false,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    className: 'carousel-left-panel',
    slidesToShow: isMobile ? 1.95 : 1.4,
  };

  const rightCarouselsettings = {
    dots: false,
    vertical: true,
    infinite: true,
    autoplay: false,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    className: 'carousel-right-panel',
    slidesToShow: 2,
    slidesToScroll: -1,
  };

  const searchAddress = () => {
    if (isGoogleMapsLoaded) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      if (autocompleteService && inputRef.current) {
        const inputText = inputRef.current.value;
        const city = currentCity ? `${currentCity}, ` : '';

        autocompleteService.getPlacePredictions(
          {
            input: city + inputText,
            types: ['geocode', 'establishment'],
            componentRestrictions: { country: "us" }
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setPredictions(predictions);
            } else {
              setPredictions([]);
            }
          }
        );
      }
    }
  };

  const addressClick = async (address) => {
    actions.sendDataToGoogleAnalytics('used_address_search', {});
    dispatch({ type: 'SET_SHOW_SEARCH_ADDRESS', payload: false });
    dispatch({ type: 'SET_LOADING', payload: true });
    await actions.fetchAddressData(address);
  };

  const handleSearchChange = (event) => {
    if (!event.target.value) {
      setPredictions([]);
    } else {
      searchAddress();
    }
  };

  const openDisclaimer = () => {
    openTutorial();
    dispatch({ type: 'SET_IS_DISCLAIMER_OPEN', payload: true });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          actions.fetchAddressByLatLng(lat, lng);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const leftCarousel = () => {
    return (
      <Slider {...leftCarouselsettings}>
        <div>
          <LazyImage
            src={carouselImages[0]}
            placeholder={carouselPlaceholders[0]}
            alt=''
          />
        </div>
        <div>
          <LazyImage
            src={carouselImages[1]}
            placeholder={carouselPlaceholders[1]}
            alt=''
          />
        </div>
        <div>
          <LazyImage
            src={carouselImages[2]}
            placeholder={carouselPlaceholders[2]}
            alt=''
          />
        </div>
      </Slider>
    );
  };

  const rightCarousel = () => {
    return (
      <Slider {...rightCarouselsettings} ref={sliderRef}>
        <div>
          <LazyImage
            src={carouselImages[3]}
            placeholder={carouselPlaceholders[3]}
            alt=''
          />
        </div>
        <div>
          <LazyImage
            src={carouselImages[4]}
            placeholder={carouselPlaceholders[4]}
            alt=''
          />
        </div>
        <div>
          <LazyImage
            src={carouselImages[5]}
            placeholder={carouselPlaceholders[5]}
            alt=''
          />
        </div>
      </Slider>
    );
  };

  const renderTutorialBtn = () => {
    return (
      <div className={`view-tutorial-btn`} onClick={() => openTutorial()}>
        <PlayIcon /> View Tutorial
      </div>
    );
  };

  const renderUserDetails = () => {
    return (
      <div className='user-details-wrapper'>
        <div className='user-icon'>
          <UserIcon />
        </div>
        <div>
          <div className='user-name'>
            Welcome,{' '}
            {isObjectEmpty(userDetails) ||
            userDetails?.email === 'mico.dahang@gmail.com'
              ? 'User'
              : userDetails?.first_name}
          </div>
          <Show.When isTrue={!isMobile}>
            <div className='logout-btn' onClick={() => actions.handleLogout()}>
              Logout
            </div>
          </Show.When>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={`landing-page-header ${!isInTrialMode && 'hide-user'}`}>
        {/* <Show.When isTrue={isInTrialMode && !isPlugin}>
          {renderUserDetails()}
        </Show.When> */}
        {renderTutorialBtn()}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className={`landing-sidebar ${isMenuOpen && 'open'}`}>
        {renderUserDetails()}
        <MenuIcon
          className='sideber-menu-btn'
          onClick={() => setIsMenuOpen(false)}
        />
        <div className='landing-sidebar-buttons'>
          <div>My Account</div>
          <div
            className='sidebar-logout-btn'
            onClick={() => actions.handleLogout()}
          >
            Log out
          </div>
        </div>
      </div>
    );
  };

  const styleWrapper = {
    ...actions.getBrandingColor(),
  };

  return (
    <div
      className={`landing-page-container
      ${isMobile && 'mobile-landing-page-container'}
      ${true && 'client-landing-page'}
    `}
    >
      <div
        className={`search-address-container ${
          isModern && 'modern-landing-page'
        }`}
        // style={styleWrapper}
      >
        <div className='landing-overlay'></div>
        <div className='landing-bg'></div>
        <Show.When isTrue={!isMobile}>{renderHeader()}</Show.When>
        <div
          className={`search-address-body ${
            isModern && 'modern-search-address-body'
          }`}
        >
          <div className='search-container'>
            <Show.When isTrue={isMobile && isInTrialMode && !isPlugin}>
              <MenuIcon
                className='landing-menu-btn'
                onClick={() => setIsMenuOpen(true)}
              />
            </Show.When>
            
            <Show.When isTrue={!clientName}>
              <WalkspanLogo className='logo' />
            </Show.When>
            <div className='slogan'>Your neighborhood, your streets, your story</div>
            <div className='slogan-subtext'> Let <b>us</b> guide your neighborhood search</div>
            <div className='search-address-input'>
              <input
                ref={inputRef}
                onChange={(e) => handleSearchChange(e)}
                placeholder='Search an address'
              />
              <div
                className={`search-address-btn ${
                  isModern && 'modern-search-address-btn'
                }`}
                // onClick={() => handleSearchClick()}
              >
                <Search />
              </div>
              <Show>
                <Show.When isTrue={predictions.length !== 0}>
                  <div
                    className={`prediction-container ${
                      currentView === 'Modern' && 'modern-prediction-container'
                    }`}
                  >
                    {predictions.map((prediction) => (
                      <div
                        className='prediction'
                        key={prediction.place_id}
                        onClick={() => addressClick(prediction.description)}
                      >
                        {prediction.description}
                      </div>
                    ))}
                  </div>
                </Show.When>
              </Show>
            </div>
            <div className='current-location-btn' onClick={() => getLocation()}>
              <CurrentLocation /> current location
            </div>
          </div>
        </div>
        {/* <Show.When isTrue={isMobile}>{leftCarousel()}</Show.When> */}
        <div className='landing-page-footer'>
          <div className='disclaimer-btn' onClick={() => openDisclaimer()}>
            <AlertIcon /> Disclaimer
          </div>
          <Show.When isTrue={isMobile}>{renderTutorialBtn()}</Show.When>
        </div>
        {/* <div
          className={`search-address-body ${
            currentView === 'Modern' && 'modern-search-address-body'
          }
          `}
        >
          <WalkspanLogo />
          <h1 class='landing-title'>
            Welcome to Walkspan <i class='walkspan-beta'> beta</i>
          </h1>
          <span class='landing-description'> neighborhood search elevated</span>
          <div className='search-address-input'>
            <input
              ref={inputRef}
              onChange={(e) => handleSearchChange(e)}
              placeholder='City, Neighborhood, Address'
            />
            <div
              className={`search-address-btn ${
                isModern && 'modern-search-address-btn'
              }`}
              // onClick={() => handleSearchClick()}
            >
              <Search />
            </div>
            <Show>
              <Show.When isTrue={predictions.length !== 0}>
                <div
                  className={`prediction-container ${
                    currentView === 'Modern' && 'modern-prediction-container'
                  }`}
                >
                  {predictions.map((prediction) => (
                    <div
                      className='prediction'
                      key={prediction.place_id}
                      onClick={() => addressClick(prediction.description)}
                    >
                      {prediction.description}
                    </div>
                  ))}
                </div>
              </Show.When>
            </Show>
          </div>
          <div
            className={`view-tutorial-btn ${
              isModern && 'modern-view-tutorial-btn'
            }`}
            onClick={() => openTutorial()}
          >
            <Info /> View Tutorial
          </div>
        </div> */}
      </div>
      <Show.When isTrue={!isMobile && false}>
        <div className='carousel-container'>
          {leftCarousel()}
          {rightCarousel()}
        </div>
      </Show.When>
      <Show.When isTrue={isMobile}>{renderSidebar()}</Show.When>
    </div>
  );
};
