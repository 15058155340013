import React, { useContext } from 'react';
import './index.css';
import { StoreContext } from '../../store';
import Slider from 'react-slick';

import DoW from '../../assets/landing-page/media/DoW.jpg';
import Mapping20 from '../../assets/landing-page/media/mapping-20-min.png';

import News1 from '../../assets/landing-page/media/news1.png';
import News2 from '../../assets/landing-page/media/news2.png';
import News3 from '../../assets/landing-page/media/news3.png';
import News5 from '../../assets/landing-page/media/news5.png';
import News6 from '../../assets/landing-page/media/news6.png';
import News7 from '../../assets/landing-page/media/news7.png';
import News8 from '../../assets/landing-page/media/news8.png';
import News9 from '../../assets/landing-page/media/news9.png';
import News10 from '../../assets/landing-page/media/news10.jpg';
import News11 from '../../assets/landing-page/media/news11.jpg';
import News12 from '../../assets/landing-page/media/news12.png';
import News4 from '../../assets/landing-page/newsletter/news4.png';

const walkabilityData = [
  {
    id: 1,
    title: "Sustainability and Walkability #1",
    description:
      "Pedestrians walk for active transportation, leisure, fitness, and mental health. When they do, other dimensions of walkability, beside safety, become important. Here we provide 10 dimensions of walkability gathered from academic literature and popular sources worldwide about what's important to the experience of walking.",
    date: "Sep 22, 2024",
    image: DoW,
    link: "https://www.linkedin.com/posts/activity-7264352470225588225--ehq/?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 2,
    title: "Sustainability and Walkability #2",
    description:
      "The 20-minute city (one-mile radius) suggests that all lifestyle essentials are within reach. Carlos Moreno, author of the 15-minute City, calls urban proximity as the 21st century approach. Proximity, livability, and social equity are the defining hallmarks of a 20-minute city.",
    date: "Sep 22, 2024",
    image: Mapping20,
    link: "https://www.linkedin.com/posts/activity-7274871045042454529-H7J9/?utm_source=share&utm_medium=member_desktop",
  },
];

const newsletters = [
    {
      id: 7,
      title: "Spoiler Alert: Carnegie Hill Last on 2024 NYC Top Ten Popular Neighborhoods - Number One on Walkspan",
      description:
        "Walkspan ran StreetEasy’s rankings against its data to show how it would change if users could evaluate the quality-of-life factors defining the neighborhoods.",
      date: "Jun 07, 2024",
      image: News8,
      link: "https://www.walkspan.com/newsletter-pages/spoiler-alert-carnegie-hill-last-on-2024-nyc-top-ten-popular-neighborhoods-number-one-on-walkspan",
    },
    {
      id: 8,
      title: "Let’s “Highline” the sidewalks of New York City",
      description:
        "“Highlining” will take to new heights the ambitious plan thoughtfully laid out in the New New York Action Plan. What better way to make New York work for everyone than by addressing conflicts where pedestrian density is at its highest? I can’t imagine a better urban strategy to make our city more inclusive than letting everyone enjoy our sidewalks.",
      date: "Mar 08, 2024",
      image: News9,
      link: "https://www.walkspan.com/newsletter-pages/lets-highline-the-sidewalks-of-new-york-city",
    },
    {
      id: 0,
      title: "Walkspan Launches Beta Version of Neighborhood Intelligence Platform",
      description:
        "Walkspan, a company that revolutionizes the real estate industry, today announced that it recently launched the beta version of their neighborhood intelligence platform designed to fully complement the property search engine used by all real estate companies.",
      date: "Mar 06, 2024",
      image: News5,
      link: "https://www.walkspan.com/newsletter-pages/walkspan-launches-beta-version-of-neighborhood-intelligence-platform",
    },
    {
      id: 1,
      title: "Barcelona Neon Crosswalks: Lighting the Way for Pedestrian Safety",
      description:
        "Neon lighting in Barcelona’s crosswalks illuminates the path to ensure pedestrian safety. This preventive measure serves as a reminder to look up from our mobile devices and cross the street safely as we traverse the digital age. It is a positive development, and Barcelona is setting the bar high.",
      date: "Oct 04, 2023",
      image: News1,
      link: "https://www.walkspan.com/newsletter-pages/barcelona-neon-crosswalks-lighting-the-way-for-pedestrian-safety",
    },
    {
      id: 2,
      title: "Sidewalk Showdown: Walkspan vs. Walk Score - What is the difference?",
      description:
        "Baby boomers and millennials share a desire for walkable communities with tree-lined streets, lively dining scenes, a variety of shopping options, spacious parks, and warm public areas. Assessment tools like Walkspan and Walk Score come into play here.",
      date: "Sep 22, 2023",
      image: News2,
      link: "https://www.walkspan.com/newsletter-pages/sidewalk-showdown-walkspan-vs-walk-score-who-scores-sidewalks-the-better",
    },
    {
      id: 3,
      title: "Treeless sidewalks in New York City",
      description:
        "Approximately 40,579 sidewalks in New York City are without trees. This accounts for 24 percent of the 168,681 sidewalk block faces in the city. Figure 1 illustrates where these treeless sidewalks are, stretching approximately 1,544 linear miles.",
      date: "Nov 16, 2022",
      image: News3,
      link: "https://www.walkspan.com/newsletter-pages/treeless-sidewalks-in-new-york-city-3n5ll",
    },
    {
      id: 4,
      title: "Our Nation’s Capital leading the fight against the climate change",
      description:
        "Green roofs help combat climate change and what better place to install them (whenever and wherever possible) than in our building height-restricted Washington DC.",
      date: "Nov 03, 2022",
      image: News4,
      link: "https://www.walkspan.com/newsletter-pages/our-nations-capital-leading-the-fight-against-the-climate-change-yx8fr",
    },

    {
      id: 4,
      title: "Location-as-a-Service (Laas)",
      description:
        "Are you a homebuyer, renter, or real estate professional just looking to learn more about the neighborhood of a property of interest? Are you tired of jumping between lists of sources like Google, Yelp, Walkscore, etc., getting lost in multiple open tabs just trying to learn the makeup of an area or neighborhood? ",
      date: "Jul 26, 2021",
      image: News10,
      link: "https://www.walkspan.com/newsletter-pages/location-as-a-service-laas-3dysz",
    },
    {
      id: 6,
      title: "A Review You’ll Want to Read",
      description:
        "Walkspan takes into account over 200 visual elements that add to one’s sense of wellness while walking. Our patented AI algorithm for rating and mapping sidewalks helps identify the good from the not so good. By good, we mean those sidewalks with numerous features and elements that enhance the experience of walking.",
      date: "Apr 21, 2021",
      image: News6,
      link: "https://www.walkspan.com/newsletter-pages/a-review-youll-want-to-read-t3dr9",
    },

    {
      id: 6,
      title: "Walkspan joins Living Lab Program to Pilot Smart City Technologies",
      description:
        "Walkspan’s location intelligence driven technology facilitates the microscale assessment and recommendation system supported by decision filters to identify walkability solutions for downtown Brooklyn.",
      date: "Sep 1, 2020",
      image: News12,
      link: "https://www.walkspan.com/newsletter-pages/walkspan-joins-living-lab-program-to-pilot-smart-city-technologies-rc9xn",
    },

    {
      id: 5,
      title: "The post-lockdown walk",
      description:
        "After the lockdown, we will all be eager to step out and embrace the world again.",
      date: "Apr 17, 2020",
      image: News11,
      link: "https://www.walkspan.com/newsletter-pages/the-post-lockdown-walk-larpa",
    },

  ];
  
  const videos = [
    {
      id: 1,
      title: "Neighborhood Character",
      thumbnail: "/images/neighborhood-character-video.png",
      link: "#",
    },
    {
      id: 2,
      title: "Neighborhood Gems",
      thumbnail: "/images/neighborhood-gems-video.png",
      link: "#",
    },
    {
      id: 3,
      title: "Compare Addresses",
      thumbnail: "/images/compare-addresses-video.png",
      link: "#",
    },
  ];

const Media = () => {
  const { state } = useContext(StoreContext);
  const { isMobile } = state;

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 3,
    swipeToSlide: false,
    arrows: true,
  };

  const walkingAndSustainability = () => {
    return (
      <div className="bg-white py-16">
        <div className="max-w-screen-xl mx-auto px-4">
          <h2 className="text-center text-3xl font-medium mb-12">
            WALKABILITY AND SUSTAINABILITY
          </h2>
          <div className="space-y-12">
            {walkabilityData.map((item) => (
              <div
                key={item.id}
                className="flex flex-col md:flex-row items-start gap-8 transition-transform transform hover:scale-105"
              >
                <div className="md:w-1/3">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full shadow-lg"
                  />
                </div>
                <div className="md:w-2/3 h-[260px]">
                  <h3 className="text-xl font-bold">{item.title}</h3>
                  <p className="text-gray-600 lg:leading-relaxed mt-4">{item.description}</p>
                  <div className="flex justify-between items-center mt-6">
                    <p className="text-sm text-gray-500">{item.date}</p>
                    <a
                      href={item.link}
                      target='_blank'
                      className="text-blue-500 text-sm font-bold hover:underline"
                    >
                      Learn more &gt;&gt;
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const newsLetter = () => {
    return (
      <div className="bg-white">
        {/* Newsletter Section */}
        <section className="py-16 newsletter-container">
          <div className="max-w-screen-xl mx-auto px-4">
            <div className="flex justify-center items-center mb-8">
              <h2 className="text-3xl font-medium text-center">NEWSLETTER</h2>
              {/* <a href="#" className="text-blue-500 text-lg">
                Next &gt;&gt;
              </a> */}
            </div>
            <Slider {...settings}>
              {newsletters.map((newsletter) => (
                <div key={newsletter.id} className="py-4 px-2">
                  <div className="shadow-md overflow-hidden flex flex-col min-h-[400px] transition-transform transform hover:scale-105 hover:shadow-lg hover:bg-gray-100">
                    <img
                      src={newsletter.image}
                      alt={newsletter.title}
                      className="w-full h-48 object-cover"
                    />
                    <div className="p-4 flex flex-col flex-grow h-[285px] max-h-[285px]">
                      <h3 className="text-lg font-medium">{newsletter.title}</h3>
                      <p className=" text-sm mt-2 flex-grow line-clamp-3">
                        {newsletter.description}
                      </p>
                      <div className="flex justify-between items-center mt-auto">
                        <p className="text-gray-500 text-sm">{newsletter.date}</p>
                        <a
                          href={newsletter.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 text-sm"
                        >
                          Read more &gt;&gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
          </div>
        </section>
  
        {/* Videos Section */}
        {/* <section className="bg-gray-50 py-16">
          <div className="max-w-6xl mx-auto px-4">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-3xl font-bold">VIDEOS</h2>
              <a href="#" className="text-blue-500 text-lg">
                Next &gt;&gt;
              </a>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {videos.map((video) => (
                <div
                  key={video.id}
                  className="relative bg-gray-200 rounded-lg overflow-hidden"
                >
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <a
                      href={video.link}
                      className="text-white text-xl font-bold"
                    >
                      ▶
                    </a>
                  </div>
                  <p className="text-center mt-4 text-gray-700">{video.title}</p>
                </div>
              ))}
            </div>
          </div>
        </section> */}
      </div>
    );
  };

    return (
        <div className='bg-white flex flex-col'>
            {walkingAndSustainability()}
            {newsLetter()}
        </div>
    )
}

export default Media;
