import { useState, useEffect, useContext, memo } from 'react';
import { useSnackbar } from 'notistack';
import { StoreContext } from '../../store';

import Show from '../../utility/Show';
import Skeleton from '../Skeleton';
import { WalkingDistanceButtons } from "../WalkingDistanceButtons/WalkingDistanceButtons";

import { ReactComponent as Favorite } from '../../assets/favorite-outline.svg';
import { ReactComponent as RecentlyViewed } from '../../assets/recently-viewed.svg';
import { ReactComponent as CircleIcon } from '../../assets/address-score/circle.svg';
import { ReactComponent as Gem } from '../../assets/address-score/gem.svg';
import { ReactComponent as View } from '../../assets/view-eye.svg';
import { ReactComponent as Delete } from '../../assets/delete.svg';

import { saveAddressToFavorites, getLifeStyleScore } from '../../services';

const Favorites = memo(( { fetchAddressData, handleTabChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch, actions } = useContext(StoreContext);
  const {
    savedAddresses,
    currentDistance,
    isStandalone,
    recentlyViewedAddresses,
    isLoading,
    isMobile,
  } = state;
  const [isMounted, setIsMounted] = useState(false);
  const [tab, setTab] = useState('favorite-list');
  const [scores, setScores] = useState([]);
  const [selectedData, setSelectedData] = useState(savedAddresses);
  const [loadingScores, setLoadingScores] = useState(false);
  const tabs = [
    {
      id: 'favorite-list',
      label: 'My Favorite List',
      icon: <Favorite className="favorite-icon" />
    },
    {
      id: 'recently-viewed',
      label: 'Recently viewed',
      icon: <RecentlyViewed className="recently-viewed-icon" />
    }
  ];

  const fetchData = async (signal) => {
    try {
      setLoadingScores(true);
      const scores = await Promise.all(
        selectedData.map(async (address) => {
          const payload = {
            latitude: address.latitude,
            longitude: address.longitude,
          };
          const feelScore = await fetchWalkIndexRatings(address.property, payload, signal);
          const gemScore = await fetchLifeStyleScores(address.property, payload, signal);

          return {
            property: address.property,
            feelScore: feelScore,
            gemScore: gemScore,
          };
        })
      );

      setScores(scores);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingScores(false);
    }
  };

  useEffect(() => {
    setIsMounted(true);

    actions.getSavedAddresses();
    actions.getRecentlyViewed();

    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);

    return () => {
      controller.abort();
    };
  }, [selectedData]);

  const handleFavTabChange = (value) => {
    if (tab !== value) {
      setTab(value);
      const data = value === 'favorite-list' ? savedAddresses : recentlyViewedAddresses;
      setSelectedData(data);
    }
  };

  const isSelected = (value) => {
    return tab === value;
  };

  const fetchWalkIndexRatings = async (address, payload, signal) => {
    try {
      const score = await actions.fetchWalkIndexRatings(payload, 0, signal, true);
      return score[0];
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
      throw error;
    }
  };

  const fetchLifeStyleScores = async (address, payload, signal) => {
    try {
      const score = await actions.fetchLifeStyleScores(payload, 0, signal, true);
      return score || {};
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
      throw error;
    }
  };

  const getOverallFeelScore = (score) => {
    if (!score) return;

    const { activities, beautyM, beautyN, comfort, noise } = score;

    const scores = [
      parseFloat(activities),
      parseFloat(beautyM),
      parseFloat(beautyN),
      parseFloat(comfort),
      parseFloat(noise),
    ];

    const counts = [0, 0, 0];
    scores.forEach((score) => {
      const roundedScore = Math.round(score);
      counts[roundedScore - 1]++;
    });

    if (counts[2] >= 1) return 3;
    if (counts[1] >= 1) return 2;
    return 1;
  };

  const lifeStyleEssentialScores = (score) => {
    if (!score) return {};

    const categories = ['Food', 'Services', 'Shops', 'Transit', 'Leisure'];
    const essentialScores = {};

    categories.forEach((category) => {
      const type = category === 'Leisure' ? 'leisure' : category.toLowerCase();
      essentialScores[category] = getLifeStyleScore(
        score[type],
        currentDistance,
        isStandalone
      );
    });

    return essentialScores;
  };

  const getOverallGemScore = (score) => {
    const scores = Object.values(lifeStyleEssentialScores(score));

    const counts = [0, 0, 0];
    scores.forEach((score) => {
      const roundedScore = Math.round(score);
      counts[roundedScore - 1]++;
    });

    if (counts[2] >= 1) return 3;
    if (counts[1] >= 1) return 2;
    return 1;
  }

  const viewAddress = (address) => {
    dispatch({ type: 'SET_CURRENT_ADDRESS', payload: null });
    fetchAddressData(address);
    handleTabChange('score');
  };

  const removeFavorite = (address) => {
    saveAddressToFavorites(
      'remove',
      address,
      savedAddresses,
      dispatch,
      enqueueSnackbar,
    );

    const filteredScores = scores.filter((score) => {
      return score.property.toLowerCase() !== address.property.toLowerCase();
    });

    setScores(filteredScores);
  }

  return (
    <div className="address-score-fav-container max-w-screen-lg">
      <Show.When isTrue={isMobile}>
        <WalkingDistanceButtons showTitle={false} isDark={true} />
      </Show.When>
      <div className="address-score-fav-header">
        <div className="tabs">
          {tabs.map((tab) => (
            <div
              className={`${isSelected(tab.id) ? 'selected' : ''}`}
              onClick={() => handleFavTabChange(tab.id)}
            >
              {tab.icon} {tab.label}
            </div>
          ))}
        </div>
        <Show.When isTrue={!isMobile}>
          <WalkingDistanceButtons showTitle={false} isDark={true} />
        </Show.When>
      </div>
      <div className="fav-table-container">
        <table>
          <thead>
            <tr>
              <th>Location</th>
              <th>Character Score</th>
              <th>Gems Score</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <Show>
              <Show.When isTrue={loadingScores}>
                <tr>
                  <td><Skeleton count={1} size={45} width='315px' baseColor='#a9a9a9' /></td>
                  <td><Skeleton count={1} size={45} width='45px' baseColor='#a9a9a9' /></td>
                  <td><Skeleton count={1} size={45} width='45px' baseColor='#a9a9a9' /></td>
                  <td><Skeleton count={1} size={45} width='45px' baseColor='#a9a9a9' /></td>
                </tr>
              </Show.When>
              <Show.Else>
                <Show>
                  <Show.When isTrue={selectedData.length > 0}>
                    {scores?.map((row, index) => (
                      <tr key={index}>
                        <td>{row.property}</td>
                        <td>
                          <div className="score">
                            <div className='score-icon-wrapper'>
                              <div
                                className='score-icon'
                                data-score-value={
                                  getOverallFeelScore(row?.feelScore)?.toString() || '1'
                                }
                              >
                                <CircleIcon />
                              </div>
                              <span className='value'>{getOverallFeelScore(row?.feelScore) || 1}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="score gem">
                            <div className='score-icon-wrapper gem'>
                              <div
                                className='score-icon gem'
                                data-score-value={
                                  getOverallGemScore(scores[index]?.gemScore).toString() || '1'
                                }
                              >
                                <Gem />
                              </div>
                              <span className='value'>{getOverallGemScore(scores[index]?.gemScore) || 1}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="actions">
                            <div className="btn" onClick={() => viewAddress(row.property)}>
                              <View />
                              View
                            </div>
                            <Show.When isTrue={tab === 'favorite-list'}>
                              <div className="btn" onClick={() => removeFavorite(row)}>
                                <Delete />
                                Delete
                              </div>
                            </Show.When>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </Show.When>
                  <Show.Else>
                    <tr>
                      <td colSpan="4" className='no-data'>No data found</td>
                    </tr>
                  </Show.Else>
                </Show>
              </Show.Else>
            </Show>
          </tbody>
        </table>
      </div>
    </div>
  )
});

export default Favorites;