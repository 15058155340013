import { useContext } from "react"
import { StoreContext } from "../../store";

import './index.css';
import { NeighborhoodScore } from "../NeighborhoodScore";
import Show from '../../utility/Show.jsx';
import { getActiveIcon, getOverallFeelScore, getOverallGemScore, getLifeStyleScore } from "../../services";
import { walkindxID } from "../../constants";

const NeighborhoodTabPopup = () => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const {
    currentTab,
    walkindexScores,
    scoreProperties,
    selectedMap,
    mapTab,
    currentDistance,
    isStandalone,
    showMapQS,
    isEstablishmentSearchOpen
  } = state;

  const isWalkIndex = currentTab === 'walkindex';

  const tabs = [
    { label: 'Neighborhood Gems', value: 'lifestyle' },
    { label: 'Neighborhood Character', value: 'walkindex' },
  ];

  const setCurrentTab = (tab) => {
    dispatch({ type: 'SET_CURRENT_TAB', payload: tab });
    dispatch({ type: 'SET_SHOW_LEGEND', payload: false });
    // const distance = isAddressInNYC ? '1' : '3';
    // dispatch({ type: 'SET_CURRENT_DISTANCE', payload: distance });
  };

  const isTabActive = (tab) => {
    return currentTab === tab;
  };

  const getScore = () => {
    if (isWalkIndex) {
      return Math.round(walkindexScores[0][walkindxID[mapTab]]) || 1;
    } else {
      return getLifeStyleScore(
        scoreProperties[selectedMap],
        currentDistance,
        isStandalone
      );
    }
  };

  return (
    <div className="neighbohood-tab-popup">
      <Show.When isTrue={!isStandalone && !isEstablishmentSearchOpen }>
        <div className="tabs-wrapper">
          {tabs.map((tab) => (
            <div
              key={tab.value}
              className={`tab-item ${
                isTabActive(tab.value) ? 'selected' : ''
              }`}
              onClick={() => setCurrentTab(tab.value)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </Show.When>
      <div className="score-wrapper">
        <NeighborhoodScore overallScore={getScore()} version={2} />
      </div>
    </div>
  )
};

export default NeighborhoodTabPopup;