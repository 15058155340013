import './index.css'
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { StoreContext } from '../../store';
import Show from '../../utility/Show';

const serviceRoutes = [
  { path: '/services/20-min-city', label: 'The 20-Minute City' },
  { path: '/services/real-estate', label: 'Real Estate' },
  { path: '/services/travel', label: 'Travel' },
];

const getServiceIndex = (pathname) => {
  return serviceRoutes.findIndex((route) => route.path === pathname);
};

const LandingHeader = () => {
  const { navigate } = useContext(StoreContext);
  const location = useLocation();
  const currentIndex = getServiceIndex(location.pathname);

  const getAlias = () => {
    const routeAliases = {
      '/services/20-min-city': 'The 20-Minute City',
      '/services/travel': 'Travel',
      '/services/real-estate': 'Real Estate',
      '/about-us': 'About Us',
      '/platform': 'Our Platform',
      '/media': 'Media',
      '/contact-us': 'Contact Us',
    };

    return routeAliases[location.pathname] || 'Unknown Page';
  };

  const prevIndex = currentIndex === 0 ? 2 : currentIndex - 1;
  const nextIndex = currentIndex === 2 ? 0 : currentIndex + 1;

  const handleNavigation = (direction) => {
    if (direction === 'back') {
      navigate(serviceRoutes[prevIndex].path);
    } else if (direction === 'forward') {
      navigate(serviceRoutes[nextIndex].path);
    }
  };

  const isServiceOrMedia = location.pathname.includes('service') || location.pathname.includes('media');

  return (
    <div className='landing-header relative'>
      <div className={`h-full flex max-w-screen-xl mx-auto justify-center items-center text-2xl md:text-4xl font-bold px-4 lg:px-0 ${
         !location.pathname.includes('service') ? '!justify-start' : ''
        }`}
      >
        {getAlias()}
        <Show.When isTrue={isServiceOrMedia}>
          <div className='absolute max-w-screen-xl bottom-2 w-full flex justify-between px-16 mb-2 2xs:px-8'>
            <Show.When isTrue={currentIndex !== -1}>
              <button
                onClick={() => handleNavigation('back')}
                className="text-lg font-medium flex items-center gap-2"
              >
                ◀ <span className='text-sm'>{serviceRoutes?.[prevIndex]?.label}</span>
              </button>
            </Show.When>
            <Show.When isTrue={currentIndex !== -1}>
              <button
                onClick={() => handleNavigation('forward')}
                className="text-lg font-medium flex items-center gap-2"
              >
                <span className='text-sm'>{serviceRoutes?.[nextIndex]?.label}</span> ▶
              </button>
            </Show.When>
          </div>
        </Show.When>
      </div>
    </div>
  )
};

export default LandingHeader;