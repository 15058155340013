import React, { useState, useContext, useRef } from 'react';
import { StoreContext } from '../../store';
import './index.css';

import { ReactComponent as Instragram } from '../../assets/social-icons/white-instagram.svg';
import { ReactComponent as LinkedIn } from '../../assets/social-icons/white-linkedin.svg';
import { ReactComponent as Twitter } from '../../assets/social-icons/white-twitterx.svg';
import { ReactComponent as Phone } from '../../assets/phone-calling.svg';
import { ReactComponent as Envelope } from '../../assets/envelope.svg';

import FormError from '../../utility/FormError.jsx';
import Show from '../../utility/Show.jsx';

import { isValidEmail } from '../../services';

import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const { state, dispatch, actions, navigate } = useContext(StoreContext);
  const { errorMessage, isLoading } = state;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const firstName = formRef.current[0].value;
    const lastName = formRef.current[1].value;
    const email = formRef.current[2].value;
    const phone = formRef.current[3].value;
    const message = formRef.current[4].value;

    if (!firstName || !lastName || !email || !phone || !message) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Please fill in all fields.'
      });
      return false;
    }

    if (!isValidEmail(email)) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Please enter a valid email address.'
      });
      return false;
    }

    actions.clearErrorMessage();

    emailjs.sendForm('service_f2wimsa', 'template_0mq1nwc', formRef.current, 'NJ0eSdxa_Pya628Qe')
      .then((result) => {
        actions.sendDataToGoogleAnalytics(
          'sent_feedback',
          {}
        );
        formRef.current.reset();
        setIsSubmitted(true);
      }, (error) => {
        console.log(error)
        setIsSubmitted(false);
      });
  };

  const renderSubmissionRecieved = () => {
    return (
      <div className='contact-us-submitted'>
        <h2 className='landing-title'>Thank You for Contacting Us!</h2>
        <div className='submission-message'>
          Thank you for reaching out to us. 
          We have received your message and our team will get back to you as soon as possible.
        </div>
        <button onClick={() => gotoHome()}>
          Back to Home
        </button>
      </div>
    )
  };

  const gotoHome = () => {
    setIsSubmitted(false);
    navigate('/home');
  };

  const renderForm = () => {
    return (
      <div className='contact-form'>
        <div class="flex justify-center relative text-white text-lg font-medium py-2 mb-4">
          CONTACT US
          <span class="absolute left-0 right-0 bottom-0 mx-auto w-14 h-[2px] bg-white"></span>
        </div>
        <form ref={formRef}>
          <div className='form-group'>
            <input
              type='text'
              name='first_name'
              placeholder='First name'
            />
            <input
              type='text'
              name='last_name'
              placeholder='Last name'
            />
          </div>
          <div className='form-group'>
            <input type='email' name='email' placeholder='E-mail' />
          </div>
          <div className='form-group'>
            <input type='text' name='phone' placeholder='Phone' />
          </div>
          <div className='form-group'>
            <textarea name='message' placeholder='Message'></textarea>
          </div>
          <Show.When isTrue={errorMessage}>
            <FormError error={errorMessage} />
          </Show.When>
          <button onClick={(e) => handleSubmit(e)}>
            Submit
          </button>
        </form>
      </div>
    )
  };

  return (
    <div className='ContactUs max-w-screen-xl mx-auto'>
      <div className='contact-info'>
        <h1 className='font-medium text-2xl lg:text-3xl'>LET'S WORK TOGETHER!</h1>
        <p className='message'>
          We're always looking for new opportunities and partners—local and
          global. Schedule a meeting!
        </p>
        <div className='contact-details'>
          <h2>Contact Detail</h2>
          <p>
            <span role='img' aria-label='email'>
              <Envelope />
            </span>{' '}
            Info@walkspan.com
          </p>
          <p>
            <span role='img' aria-label='phone'>
              <Phone />
            </span>{' '}
            914-419-0667
          </p>
        </div>
        <div className='social-media'>
          <h2>Connect with us!</h2>
          <div className='links'>
            <a target='_blank' href='https://www.instagram.com/walkspan_nyc/'>
              <Instragram />
            </a>
            <a target='_blank' href='https://www.linkedin.com/company/walkspan-inc'>
              <LinkedIn />
            </a>
            <a target='_blank' href='https://twitter.com/walkspan_walks'>
              <Twitter />
            </a>
          </div>
        </div>
      </div>
      <Show>
        <Show.When isTrue={isSubmitted}>
          {renderSubmissionRecieved()}
        </Show.When>
        <Show.Else>
          {renderForm()}
        </Show.Else>
      </Show>
    </div>
  );
};

export default ContactUs;
