import React, { useContext } from 'react';
import { StoreContext } from '../../store';
import './index.css';

import { ReactComponent as WalkIndexIcon } from '../../assets/walkindex-icon.svg';
import { ReactComponent as GemIcon } from '../../assets/gem-icon.svg';
import { ReactComponent as ModernGem } from '../../assets/modern-gem.svg';
import { ReactComponent as LegendScore1 } from '../../assets/legend-score-1.svg';
import { ReactComponent as LegendScore2 } from '../../assets/legend-score-2.svg';
import { ReactComponent as LegendScore3 } from '../../assets/legend-score-3.svg';

import { ReactComponent as ActiveGem1 } from '../../assets/mobile-active-scores/gem-1.svg';
import { ReactComponent as ActiveGem2 } from '../../assets/mobile-active-scores/gem-2.svg';
import { ReactComponent as ActiveGem3 } from '../../assets/mobile-active-scores/gem-3.svg';
import { ReactComponent as ActiveFeel1 } from '../../assets/mobile-active-scores/feel-1.svg';
import { ReactComponent as ActiveFeel2 } from '../../assets/mobile-active-scores/feel-2.svg';
import { ReactComponent as ActiveFeel3 } from '../../assets/mobile-active-scores/feel-3.svg';
import { ReactComponent as Home } from '../../assets/home.svg';

import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import NSVersion3 from './version3';
import GraphMapToggle from '../CompareMaps/graph-map-toggle';

import {
  getLifeStyleScore,
  getLimit,
  getTypeDescription,
  getLifeStyleWording,
  getWalkIndexWording,
  getFeatureIcon,
  getTotalGems,
  getSoloFeelScore,
} from '../../services';
import { formatGemNumber } from '../../helper';

import { ScoreIcon } from "../../utility/ScoreIcon";

import { walkIndexFeatures, lifeStyleFeatures, walkindxID } from '../../constants';
import MapTypeLegends from '../LegendLines';


import Skeleton from '../Skeleton';
import { Tooltip } from 'react-tooltip';

import Show from '../../utility/Show';

export const getActiveIcon = (score, currentTab) => {
  const isWalkIndex = currentTab === 'walkindex'
  const gemIcons = [ActiveGem1, ActiveGem2, ActiveGem3];
  const feelIcons = [ActiveFeel1, ActiveFeel2, ActiveFeel3];

  const icons = isWalkIndex ? feelIcons : gemIcons;
  const IconComponent = icons[score - 1] || null; // Ensure score is within range (1-3)

  const icon = IconComponent ? <IconComponent /> : null;

  return (
    <div className={`active-score-icon-wrapper ${
      !isWalkIndex ? 'gem' : ''
    }`}
    >
      {icon}
      <span>{score}</span>
    </div>
  )
};

export const NeighborhoodScore = ({ overallScore, version = 1 }) => {
  const { state, actions, dispatch } = useContext(StoreContext);

  const {
    isLoading,
    walkindexScores,
    lifeStyleScores,
    isStandalone,
    mapTab,
    currentTab,
    listOfMarkers,
    showDashboard,
    currentView,
    currentDistance,
    showEstablishments,
    isMobile,
    isMapLoading,
    selectedMap,
    currentWalkindxType,
    categoryFilters,
    currentRates,
    showLegend,
    showCompareLifeStyle,
    showCompareGraph,
    showFilter,
    currentMarker,
    showComparePopup,
  } = state;

  const isWalkIndex = currentTab === 'walkindex';
  const isModern = currentView === 'Modern';

  const getMapTabIcon = () => {
    const selectedFeatures = isWalkIndex
      ? walkIndexFeatures
      : lifeStyleFeatures;
    return selectedFeatures
      .filter(
        (feature) =>
          feature.name.toLocaleLowerCase() === mapTab.toLocaleLowerCase()
      )
      .map((feature) => feature.svg);
  };

  const legend = [
    { label: 'Meh', value: 1 },
    { label: 'Yeah', value: 2 },
    { label: 'Wow', value: 3 },
  ];

  const isFilterActive =
    categoryFilters.length > 1 ||
    categoryFilters[0] !== '' ||
    (currentRates.length !== 0 && currentRates[0] !== '');

  const getWalkableTitle = () => {
    return getWalkIndexWording(overallScore);
  };

  const getWalkableDescription = (score) => {
    const limit = getLimit(currentDistance);

    if (score === 1) {
      return isWalkIndex
        ? 'Low presence of features <25%'
        : `${getValueDescription()} living essentials`;
    } else if (score === 2) { 
      return isWalkIndex
        ? 'Moderate presence of features >25% <50%'
        : `${getValueDescription()} living essentials`;
    } else {
      return isWalkIndex
        ? 'High presence of features >50%'
        : `${getValueDescription()} living essentials`;
    }
  };

  const getValueDescription = () => {
    return getTypeDescription(
      currentTab,
      mapTab.toLowerCase(),
      overallScore,
      currentDistance,
      isStandalone
    );
  };

  const walkindxRadius = (score) => {
    if (score === 1) {
      return '<25%';
    } else if (score === 2) {
      return '>25% <50%';
    } else {
      return '>50%';
    }
  };

  const lifeStyleEssentialScores = () => {
    if (!lifeStyleScores) return {};

    const categories = ['Food', 'Services', 'Shops', 'Transit', 'Leisure'];
    const essentialScores = {};

    categories.forEach((category) => {
      const type =
        category === 'Leisure'
          ? 'entertainment-fitness'
          : category.toLowerCase();
      essentialScores[category] = getLifeStyleScore(
        lifeStyleScores?.[0]?.[type] || 1,
        currentDistance,
        isStandalone
      );
    });

    return essentialScores;
  };

  const getOverallScore = () => {
    if (isWalkIndex) {
      if (!walkindexScores[0]) return {};
      const { activities, beautyM, beautyN, comfort, noise } =
        walkindexScores[0];

      const scores = [
        parseFloat(activities),
        parseFloat(beautyM),
        parseFloat(beautyN),
        parseFloat(comfort),
        parseFloat(noise),
      ];

      const counts = [0, 0, 0];
      scores.forEach((score) => {
        const roundedScore = Math.round(score);
        counts[roundedScore - 1]++;
      });

      if (counts[2] >= 1) return 3;
      if (counts[1] >= 1) return 2;
      return 1;
    } else {
      const scores = Object.values(lifeStyleEssentialScores());

      const counts = [0, 0, 0];
      scores.forEach((score) => {
        const roundedScore = Math.round(score);
        counts[roundedScore - 1]++;
      });

      if (counts[2] >= 1) return 3;
      if (counts[1] >= 1) return 2;
      return 1;
    }
  };

  const renderLegends = () => {
   
    return (
      <div
        className={`neighborhood-score-legend-container ${
          isModern && 'modern-neighborhood-score-legend-container'
        }`}
      >
        {legend.map((item, index) => {
          return (
            <div
              className='neighborhood-legend-label-container'
              key={`neighborhood-legend-${index}`}
            >
              <div
                data-tooltip-id={`${item.label}-label`}
                className={`small-score-icon ${
                  !isWalkIndex && !isModern && 'small-score-lifestyle'
                } ${isModern && 'modern-small-score-icon'} ${
                  isModern && !isWalkIndex && 'modern-small-score-lifestyle'
                }`}
              >
                <Show>
                  <Show.When isTrue={version === 1}>
                    <Show>
                      <Show.When isTrue={isWalkIndex}>
                        <div
                          className='icon-score-cirlce'
                          data-score-value={item.value?.toString()}
                          style={scoreCircleStyle(item.value)}
                        ></div>
                      </Show.When>
                      <Show.Else>
                        <div
                          className='score-diamond'
                          data-score-value={item.value?.toString()}
                        >
                          <ModernGem />
                        </div>
                      </Show.Else>
                    </Show>
                    {!isModern && (isWalkIndex ? <WalkIndexIcon /> : <GemIcon />)}
                    <h1>{item.value}</h1>
                  </Show.When>
                  <Show.Else>
                    {item.value?.toString()}
                  </Show.Else>
                </Show>
              </div>
              <p>{item.label}</p>
              <Tooltip
                className='legend-popup'
                id={`${item.label}-label`}
                place='bottom'
              >
                {getWalkableDescription(item.value)}{' '}
                {walkindxRadius(getOverallScore())}
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  };

  const getEstablishmentType = () => {
    if (mapTab === 'All') {
      return 'Gems';
    } else if (mapTab === 'Food') {
      return 'Restaurants';
    } else if (mapTab === 'Transit' || mapTab === 'Leisure') {
      return mapTab + 's';
    } else {
      return mapTab;
    }
  };

  const getFeelLineLegend = (score) => {
    const lines = [LegendScore1, LegendScore2, LegendScore3];

    const IconComponent = lines[score - 1] || null; // Ensure score is within range (1-3)

    return IconComponent ? <IconComponent /> : null;
  }

  const getLegendLabel = (index) => {
    if (!isWalkIndex) {
      const percentageLabel = {
        1: '< 50%',
        2: '≈ 50%',
        3: '> 50%',
      }[index]
      return `${percentageLabel} ${getEstablishmentType(index)}`
    }
  
    return getTypeDescription('walkindex', mapTab?.toLowerCase(), index, currentDistance, isStandalone, true)
  }

  const renderMapTypeLegends = () => {
    return (
      <div className="map-type-legends">
        <div
          className="map-type-legend"
          data-border-style="1"
          style={getTabColor()}
        >
          <Show.When isTrue={isWalkIndex}>
            {getFeelLineLegend(1)}
          </Show.When>
          {getLegendLabel(1)}
        </div>
        <div
          className="map-type-legend"
          data-border-style="2"
          style={getTabColor()}
        >
          <Show.When isTrue={isWalkIndex}>
            {getFeelLineLegend(2)}
          </Show.When>
          {getLegendLabel(2)}
        </div>
        <div
          className="map-type-legend"
          data-border-style="3"
          style={getTabColor()}
        >
          <Show.When isTrue={isWalkIndex}>
            {getFeelLineLegend(3)}
          </Show.When>
          {getLegendLabel(3)}
        </div>
      </div>
    );
  };

  const scoreCircleStyle = (score) => {
    let scoreValue = 0;
    if (score === 1) {
      scoreValue = 0.25;
    } else if (score === 2) {
      scoreValue = 0.5;
    } else {
      scoreValue = 1;
    }
    const style = {
      '--score-value': scoreValue,
    };
    return style;
  };

  const getTabColor = (returnAsObject=true) => {
    const selectedFeatures = isWalkIndex
      ? walkIndexFeatures
      : lifeStyleFeatures;
    const selectedColor = selectedFeatures
      .filter(
        (feature) =>
          feature.name.toLocaleLowerCase() === mapTab.toLocaleLowerCase()
      )
      .map((feature) => feature.color)[0];

    if (returnAsObject) {
      return { '--tab-color': selectedColor };
    } else {
      return selectedColor;
    }
  };

  const toggleShowEstablishments = () => {
    if (isMobile) {
      dispatch({ type: 'SET_MOBILE_SIDEBAR', payload: false });
    }

    if (listOfMarkers[0]?.length) {
      dispatch({
        type: 'SET_SHOW_ESTABLISHMENTS',
        payload: !showEstablishments,
      });
    }
  };

  const getTabLabel = () => {
    if (mapTab === 'All') {
      return `Gem${getTotalGems(lifeStyleScores[selectedMap]) > 1 ? 's' : ''}`;
    } else {
      return mapTab;
    }
  };

  const handleShowMarkerList = () => {
    dispatch({ type: 'SET_SHOW_MARKER_LIST', payload: true });
    dispatch({ type: 'SET_SELECTED_MAP', payload: 0 });
  };

  const handleShowLegend = () => {
    dispatch({ type: 'SET_SHOW_LEGEND', payload: !showLegend });
  }

  const renderVersion1 = () => {
    return (
      <Show>
        <Show.When isTrue={loading}>
          <Show.When isTrue={showDashboard}>
            <Skeleton count={1} size={80} baseColor='#a9a9a9' />
            <Skeleton count={1} size={30} baseColor='#a9a9a9' />
          </Show.When>
          <Show.When isTrue={!showDashboard}>
            <Skeleton count={1} size={80} width={80} baseColor='#a9a9a9' />
            <Skeleton count={1} size={30} width={150} baseColor='#a9a9a9' />
            <Skeleton count={1} size={30} width={150} baseColor='#a9a9a9' />
          </Show.When>
        </Show.When>
        <Show.Else>
          <ScoreIcon
            height={showDashboard ? '100px' : '80px'}
            width='auto'
            withBg={true}
            fontSize="45px"
            score={overallScore?.toString()}
            content={overallScore?.toString()}
          />

          <Show.When isTrue={showDashboard}>
            <h2 className='score-title'>{getWalkableTitle()}</h2>
          </Show.When>

          <Show.When isTrue={showDashboard}>
            <span className='score-description'>
              {getWalkableDescription(getOverallScore())}
              <Show.When isTrue={isWalkIndex && showDashboard}>
                {' '}
                <span className='score-description'>
                  {walkindxRadius(getOverallScore())}
                </span>
              </Show.When>
            </span>
          </Show.When>

          <Show.When isTrue={!showDashboard}>
            <div className='score-current-tab'>
              {getMapTabIcon()}
              {mapTab}
            </div>
          </Show.When>

          <Show.When isTrue={!showDashboard}>
            <div
              className='tab-score-desciption'
              data-border-style={'3'}
              style={getTabColor()}
            >
              {getValueDescription()}
            </div>
          </Show.When>

          <Show.When isTrue={!showDashboard && !isWalkIndex}>
            <div className='total-establishment-container'>
              Total No. of {getEstablishmentType()}:{' '}
              <span
                className='total-establishment-value'
                style={getTabColor()}
                onClick={toggleShowEstablishments}
              >
                {listOfMarkers[0]?.length}
              </span>
            </div>
          </Show.When>
        </Show.Else>
      </Show>
    )
  };

  const renderCollapseBtn = (
    <div className='cursor-pointer translate-y-1' onClick={handleShowLegend}>
      { showLegend ? <FaCaretUp className='w-[22.5px] h-[22.5px]'/> : <FaCaretDown className='w-[22.5px] h-[22.5px]'/>}
    </div>
  )

  const handleComparePopup = () => {
    if (showFilter) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }

    if (currentMarker[selectedMap]) {
      dispatch({
        type: 'SET_CURRENT_MARKER',
        payload: { markerIndex: 0, markerData: null },
      });
    }

    dispatch({
      type: 'SET_SHOW_COMPARE_POPUP',
      payload: !showComparePopup
    });
    // handleMobileMapControls();
  };

  const renderVersion2 = () => {
    const currentTabScore = isWalkIndex ? getSoloFeelScore(walkindexScores?.[selectedMap]?.[walkindxID?.[mapTab]]) : overallScore

    return (
      <Show>
        <Show.When isTrue={loading}>
          <div className='version-2-wrapper'>
            <Skeleton count={1} size={80} width={"100%"} baseColor='#a9a9a9' />
            {/* <Skeleton count={1} size={60} width={140} baseColor='#a9a9a9' />
            <Skeleton count={1} size={60} width={70} baseColor='#a9a9a9' /> */}
          </div>
        </Show.When>
        <Show.Else>
          <div className={`version-2-wrapper ${!isWalkIndex ? 'gem' : ''}`}>
            {/* {getActiveIcon(overallScore, currentTab)} */}
            {/* <div className='score-details'>
              <div className='score-title'>
                {isWalkIndex ? 'Feel' : 'Gem'} Score
              </div>
              <div className='score-value'>{getWalkableTitle()}</div>
              <div className='score-description'>
                {getWalkableDescription(overallScore)}
              </div>
            </div> */}
            <Show>
              <Show.When isTrue={isMobile && showCompareLifeStyle}>
                <GraphMapToggle state={state} dispatch={dispatch} />
              </Show.When>
              <Show.Else>
                <Show>
                  <Show.When isTrue={isWalkIndex}>
                    <div className="selected-feel-feature text-xs sm:text-sm md:text-base lg:text-base" style={getTabColor()}>
                      {mapTab}
                      <div className='flex items-end gap-1 mt-2'>
                        {getFeatureIcon(true, mapTab)} {renderCollapseBtn}
                      </div>
                      {/* <div className="tab-score-desciption">
                        {getFeelLineLegend(currentTabScore)}
                        {getTypeDescription(
                          currentTab.toLowerCase(),
                          mapTab.toLowerCase(),
                          Math.round(
                            parseFloat(
                              walkindexScores?.[selectedMap]?.[walkindxID?.[mapTab]]
                            )
                          ) || 1,
                          currentDistance,
                          isStandalone,
                          true
                        )}
                      </div> */}
                    </div>
                  </Show.When>
                  <Show.Else>
                    <div className="total-gems" style={getTabColor()}>
                      {mapTab}
                      <div className="flex gap-1 items-end mt-2">
                        {getFeatureIcon(false, mapTab)}{renderCollapseBtn}
                      </div>
                      <div className='flex gap-x-2 items-center'>
                        <Show.When isTrue={true}>
                          <div
                            className={`cursor-pointer tab-pill mt-3 text-[10px]`}
                            onClick={() => handleShowMarkerList()}
                          >
                            {formatGemNumber(listOfMarkers[selectedMap]?.length)} {`${listOfMarkers[selectedMap]?.length > 1 ? 'Gems' : 'Gem' }`}
                          </div>
                        </Show.When>
                      </div>
                    </div>
                  </Show.Else>
                </Show>
              </Show.Else>
            </Show>
            <Show>
              <Show.When isTrue={showCompareGraph && showCompareLifeStyle}>
                <div className='change-addresses-btn' onClick={() => handleComparePopup()}>
                  <Home /> Change Addresses
                </div>
              </Show.When>
              <Show.Else>
                <NSVersion3 isWalkIndex={isWalkIndex} score={currentTabScore}/>
              </Show.Else>
            </Show>
          </div>
        </Show.Else>
      </Show>
    )

  };


  const loading = showDashboard ? isLoading : isMapLoading[0];

  return (
    <div
      className={`neighborhood-score-container ${
        isModern && 'modern-neighborhood-score-container'
      } ${!showDashboard && 'sidebar-neighborhood-score-container'}`}
      style={actions.getBrandingColor()}
    >
      <Show.When isTrue={showDashboard}>
        <div className='neighborhood-score-title'>
          {isWalkIndex ? 'Character' : 'Gem'} Score
        </div>
      </Show.When>
      <Show>
        <Show.When isTrue={version === 1}>
          {renderVersion1()}
        </Show.When>
        <Show.Else>
          {renderVersion2()}
        </Show.Else>
      </Show>

      <Show.When isTrue={isModern && showDashboard}>
        {renderLegends()}
      </Show.When>
    </div>
  );
};