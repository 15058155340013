import React, { useState, useEffect } from "react";

const IframePreview = () => {
  const [width, setWidth] = useState("100%");
  const [height, setHeight] = useState("600px");
  const [addressSuffix, setAddressSuffix] = useState("845 United Nations, New York, NY");
  const [suffix, setSuffix] = useState("");
  const [city, setCity] = useState("");
  const [iframeSrc, setIframeSrc] = useState("");
  const [loading, setLoading] = useState(false);

  const cityList = [
    "New York",
    "Washington",
    "Chicago",
    "Seattle",
    "Boston",
    "Los Angeles",
    "Austin",
    "Philadelphia",
    "Sioux Falls",
    "Massachusetts",
  ];

  const baseURL = "https://senseofplace.ai/?";

  useEffect(() => {
    // Initial load of iframe preview
    setIframeSrc(`${baseURL}address=${addressSuffix}&city=${city}&suffix=${suffix}`);
  }, []); // Runs only once on component mount

  useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => {
        setIframeSrc(`${baseURL}address=${addressSuffix}&city=${city}&suffix=${suffix}`);
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [loading, addressSuffix, city, suffix]);

  const iframeCode = `<iframe 
  src="${iframeSrc}" 
  width="${width}" 
  height="${height}" 
  allowFullScreen 
  style="border: none;"></iframe>`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(iframeCode);
    alert("Code copied to clipboard!");
  };

  const updateIframe = () => {
    setLoading(true);
  };

  const validateDimension = (value) => {
    return /^(\d+px|\d+%)$/.test(value);
  };

  const handleWidthChange = (value) => {
    if (validateDimension(value)) {
      setWidth(value);
    } else {
      alert("Invalid format. Use a number followed by 'px' or '%', e.g., '600px' or '100%'.");
    }
  };

  const handleHeightChange = (value) => {
    if (validateDimension(value)) {
      setHeight(value);
    } else {
      alert("Invalid format. Use a number followed by 'px' or '%', e.g., '400px' or '50%'.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-5xl mx-auto bg-white shadow-md rounded-md p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          WalkSpan Plugin Generator
        </h1>
        <p className="text-gray-600 mb-4">
          Customize the iframe preview below by entering the desired address
          suffix, width, height, city, and a business suffix for tracking events.
        </p>

        {/* Input Fields */}
        <div className="space-y-4">
          <div>
            <label className="block text-gray-700 font-semibold mb-1">
              Address Suffix
            </label>
            <input
              type="text"
              value={addressSuffix}
              onChange={(e) => setAddressSuffix(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="e.g., 123-Main-Street"
            />
            <p className="text-gray-500 text-sm mt-1">
                IMPORTANT: If your website is dynamically loading address, please ensure you put the address as variable
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
                <label className="block text-gray-700 font-semibold mb-1">
                Business Suffix
                </label>
                <input
                type="text"
                value={suffix}
                onChange={(e) => setSuffix(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="e.g., my-business-name"
                />
                <p className="text-gray-500 text-sm mt-1">
                This represents your business name for tracking user events.
                </p>
            </div>

            <div>
                <label className="block text-gray-700 font-semibold mb-1">
                City
                </label>
                <select
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                >
                <option value="">Show All</option>
                {cityList.map((cityOption) => (
                    <option key={cityOption} value={cityOption}>
                    {cityOption}
                    </option>
                ))}
                </select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-semibold mb-1">
                Width (px or %)
              </label>
              <input
                type="text"
                value={width}
                onChange={(e) => handleWidthChange(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="e.g., 600px or 100%"
              />
            </div>

            <div>
              <label className="block text-gray-700 font-semibold mb-1">
                Height (px or %)
              </label>
              <input
                type="text"
                value={height}
                onChange={(e) => handleHeightChange(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="e.g., 400px or 50%"
              />
            </div>
          </div>
        </div>

        <button
          onClick={updateIframe}
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Generate Preview
        </button>

        {/* Iframe Preview */}
        <div className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Preview:
          </h2>
          {loading ? (
            <div className="text-center py-4">Loading preview...</div>
          ) : (
            <iframe
              src={iframeSrc}
              width={width}
              height={height}
              allowFullScreen
              style={{ border: "none" }}
              className="w-full border border-gray-300"
              title="Iframe Preview"
            ></iframe>
          )}
        </div>

        {/* Code Output */}
        <div className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Generated Code:
          </h2>
          <textarea
            className="w-full p-2 border border-gray-300 rounded bg-gray-100"
            rows={10}
            readOnly
            value={iframeCode}
          ></textarea>
          <button
            onClick={copyToClipboard}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Copy Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default IframePreview;
