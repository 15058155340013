import React, { useContext } from 'react';
import { StoreContext } from '../../store';
import './index.css';

import Slider from 'react-slick';

import BG1 from '../../assets/landing-page/about-us-bg.jpg';
import BG1PH from '../../assets/landing-page/about-us-bg-ph.jpg';
import BG2 from '../../assets/landing-page/what-we-do.png';

import { ReactComponent as MeasureArrow } from '../../assets/landing-page/wwm/arrow.svg';

import LinkedInWhite from '../../assets/landing-page/linkedin-white.svg';
import GmailWhite from '../../assets/landing-page/gmail-white.svg';

import LinkedIn from '../../assets/landing-page/linkedin.svg';
import Gmail from '../../assets/landing-page/gmail.svg';

import { teamData, advisorsData } from '../../constants';
import Team1 from '../../assets/landing-page/team/Bernardita C.jpg';

import LazyImage from '../../utility/LazyImage';

const About = () => {
  const { state } = useContext(StoreContext);
  const { isMobile } = state;
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 3,
    swipeToSlide: false,
    arrows: true,
  };

  return (
    <div className='About'>
      <section className='who-we-are'>
        {/* <LazyImage src={BG1} placeholder={BG1PH} alt='' /> */}
        <div className="bg-[#211F1F] py-12">
          <div className="max-w-screen-xl mx-auto text-lg grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
            <div className="bg-[#121212] text-white p-6 rounded-lg shadow-md text-left">
              <p>
                Walkspan is a sustainable company with a unique value system and mission to shape the 
                public realm of tomorrow. We are passionate about celebrating 
                <span className="font-bold"> neighborhood character and quality.</span>
              </p>
            </div>
            <div className="bg-[#121212] text-white p-6 rounded-lg shadow-md text-left">
              <p>
                We are a data and <span className="font-bold">technology company</span> dedicated to accelerating public 
                realm transformation through <span className="font-bold">AI-driven metrics.</span>
              </p>
            </div>
            <div className="bg-[#121212] text-white p-6 rounded-lg shadow-md text-left">
              <p>
                We are impact entrepreneurs, an interdisciplinary team in the technology, 
                <span className="font-bold"> location intelligence</span>, data, and environmental fields.
              </p>
            </div>
          </div>
        </div>
        {/* <section className='what-we-do max-w-screen-lg'>
          <h1 className='landing-title'>What We Do</h1>
          <p>
            Walkspan reveals neighborhood character and quality through its
            neighborhood intelligence platform. A platform that seamlessly
            integrates with real estate search engines to help buyers and
            renters find homes in walkable neighborhoods. Buying homes where
            lifestyle essentials are within walking distance improves quality of
            life and brings sustainable impacts.
          </p>
        </section> */}
      </section>

      {/* <section className='meet-our-team'>
        <h1 className='landing-title'>Meet Our Team</h1>
        <p className='quote'>
          We have <b>35 years</b> of combined experience in urban environment,
          data management and location intelligence.
        </p>
        <div className='team-list'>
          {teamData.map((member, index) => (
            <div key={index} className='team-item'>
              <img src={member.img} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.title}</p>
              <div className='team-links'>
                <a
                  href={member.linkedIn}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={LinkedIn} alt='LinkedIn' />
                </a>
                <a href={member.email}>
                  <img src={Gmail} alt='Email' />
                </a>
              </div>
              <div className='team-affiliations'>
                {member.affiliations.map((logo, logoIndex) => (
                  <img key={logoIndex} src={logo} alt='Affiliation' />
                ))}
              </div>
            </div>
          ))}
        </div>
      </section> */}
      <div className="bg-white py-12">
      <div className="max-w-screen-lg mx-auto px-4 mb-4">
          <p className="text-center text-gray-700 text-lg font-medium max-w-screen-md mx-auto">
            We have <span className="font-bold">35 years</span> of combined experience in data management, location intelligence, urban studies, and operations.
          </p>
          <div className='flex flex-col lg:flex-row gap-4 mt-16'>
            <div className="flex flex-col mx-auto w-[235px]">
              <div className="">
                <img
                  src={Team1} 
                  alt="Bernardita Calinao, PhD"
                  className="w-48 h-48"
                />
              </div>
              <div className="text-center md:text-left mt-6 md:mt-2">
                <h2 className="font-bold text-gray-900">Bernardita Calinao, PhD</h2>
                <p className="text-gray-600 font-medium">Founder and CEO</p>
                <div className='flex gap-2 items-center advisor-links pl-0'>
                  <a
                    href="https://www.linkedin.com/in/bernarditacaliano"
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={LinkedIn} alt='LinkedIn' />
                  </a>
                  <a href="mailto:bernardita@example.com">
                    <img src={Gmail} alt='Email' />
                  </a>
                </div>
              </div>
            </div>

            <div className="text-gray-700 text-base leading-7 text-left lg:w-4/5">
              <p>
                <span className="font-bold">Bernardita</span> is passionate about urban livability, walkability, and sustainability. As a Human Ecologist and Environmental Planner, she has directed over 35 environmental and sustainable development studies for multimillion infrastructure investment projects in the US and abroad. Bernardita has been involved in multi-sector stakeholder negotiations representing international, private, government sectors and indigenous communities. Several of her projects won awards for excellence in engineering through the application of geographic information systems to map environmental impacts and place-based environmental solutions. 
              </p>
              <p className="mt-6">
                Six years ago, Bernardita launched Walkspan. Her interest in walkability and urban livability is deeply rooted in her years of environmental work in the transportation and urban sectors. She now dedicates her career towards unfolding the power of place by leveraging the largest repository of data on the walking environment. Bernardita holds PhD and Master’s degrees in Environmental Science from the State University of New York College of Environmental Science and Forestry, Syracuse, New York.
              </p>
              <p className="mt-6 italic">
                "Walkspan is fortunate to be fully supported by and amazing team of software developers, data scientists, location and urban planning experts in the US and abroad."
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className='meet-our-advisors'>
        <h1 className='landing-title'>Meet Our Advisors</h1>
        <p className='quote'>
          We have a <b>network of professionals</b> in real estate technology
          and marketing to create synergies and share ideas on walkability.
        </p>
        <div className='max-w-[300px] md:max-w-[850px] my-0 mx-auto relative'>
          <Slider {...settings}>
            {advisorsData.map((advisor, index) => (
              <div key={index} className='advisor-item max-w-fit w-full flex justify-center text-left'>
                <img src={advisor.img} alt={advisor.name} />
                <h3>{advisor.name}</h3>
                <p className='text-left'>{advisor.title}</p>
                <div className='flex gap-2 advisor-links pl-4'>
                  <a
                    href={advisor.linkedIn}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={LinkedInWhite} alt='LinkedIn' />
                  </a>
                  {/* <a href={advisor.email}>
                    <img src={GmailWhite} alt='Email' />
                  </a> */}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default About;
