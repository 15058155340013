import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './index.css';

import { ReactComponent as Logo } from '../../assets/walkspan-logo-2.svg';
import { ReactComponent as LinkedIn } from '../../assets/social-icons/linkedin-black.svg';
import { ReactComponent as Instragram } from '../../assets/social-icons/instagram-icon.svg';
import { ReactComponent as Twitter } from '../../assets/social-icons/twitterx-icon.svg';

import { StoreContext } from '../../store';

import Show from '../../utility/Show';

const LandingNav = () => {
  const { state, navigate } = useContext(StoreContext);
  const { isMobile } = state;

  const location = useLocation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navLinks = [
    { path: '/home', label: 'Home' },
    { path: '/about-us', label: 'About Us' },
    { isDropdown: true, label: 'Services', path: '/services', items: [
        { path: '/services/real-estate', label: 'Real Estate' },
        { path: '/services/travel', label: 'Travel' },
        { path: '/services/20-min-city', label: '20-min City' },
      ]
    },
    { path: '/media', label: 'Media' },
    { path: '/contact-us', label: 'Contact Us' },
  ];

  const isActive = (path) => location.pathname.startsWith(path);

  const handleLinkClick = (link) => {
    navigate(link.path);
    document.title = `${link.label} - Walkspan`;
  };

  return (
    <div className="landing-nav py-1 px-4 lg:px-0">
      <Logo className="walkspan-logo w-[150px] h-[75px]" onClick={() => navigate('/home')} />
      <div className="landing-nav-buttons">
        {navLinks.map((link, index) => {
          if (link.isDropdown) {
            return (
              <div
                key={index}
                className={`relative cursor-pointer ${isActive(link.path) ? 'active-header' : ''}`}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                {link.label}
                {isDropdownOpen && (
                  <section className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-48 bg-white shadow-lg z-50 py-1">
                    {link.items.map((item) => (
                      <div
                        key={item.path}
                        onClick={() => {
                          setIsDropdownOpen(false);
                          handleLinkClick(item);
                        }}
                        className="text-sm py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                      >
                        {item.label}
                      </div>
                    ))}
                  </section>
                )}
              </div>
            );
          } else {
            return (
              <div
                key={link.path}
                onClick={() => handleLinkClick(link)}
                className={`${isActive(link.path) ? 'active-header' : ''}`}
              >
                {link.label}
              </div>
            );
          }
        })}
      </div>
      <Show.When isTrue={!isMobile}>
        <div>
          <div className="landing-nav-socials">
            <a target="_blank" href="https://www.instagram.com/walkspan_nyc/">
              <Instragram />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/walkspan-inc"
            >
              <LinkedIn />
            </a>
            <a target="_blank" href="https://twitter.com/walkspan_walks">
              <Twitter />
            </a>
          </div>
        </div>
      </Show.When>
    </div>
  );
};

export default LandingNav;
