import { useRef, useEffect } from "react";

import Chart from "chart.js/auto";

import Show from "../../utility/Show";

import {
  getFeatureIcon,
  getTabColor,
  getFeelLineLegend2,
  legendWalkIndexDescription,
  hexToRgb,
  getDescription,
  findColor,
  getSoloFeelScore,
} from "../../services";

import { walkIndexFeatures, lifeStyleFeatures, filteredLifeStyleFeatures } from "../../constants";

const CompareGraph = ({ state, dispatch }) => {
  const {
    currentTab,
    mapTab,
    showFilter,
    showMarkerList,
    lifeStyleCompareMaps,
    lifeStyleScores,
    walkindexScores,
    compareLifeStyleAddress,
    isMobile,
    showCompareGraph,
  } = state;

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const isWalkIndex = currentTab === 'walkindex';
  const isAll = mapTab === 'All';

  const filteredGemFeatures = lifeStyleFeatures.slice(1, lifeStyleFeatures.length - 1);

  useEffect(() => {
    const ctx = chartRef?.current?.getContext("2d");

    // If the chart instance already exists, update it
    if (chartInstance.current) {
      chartInstance.current.data.datasets = generateDatasets();
      chartInstance.current.update(); // Update the chart with new data
    } else {
      // Create a new chart instance
      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ['A1', 'A2', 'A3', 'A4'],
          datasets: generateDatasets(),
        },
        options: {
          responsive: true,
          layout: {
            padding: {
              top: 20, // Add padding to the top
            },
          },
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false,
            },
            tooltip: {
              enabled: true, // Enable tooltips
              displayColors: !isWalkIndex,
              callbacks: {
                title: (tooltipItems) => {
                  const index = tooltipItems[0].dataIndex;
                  // Customize the tooltip title (e.g., bar label)
                  return `${compareLifeStyleAddress[index]?.shortName || compareLifeStyleAddress[index]?.property}`;
                },
                label: (tooltipItem) => {
                  const datasetLabel = tooltipItem.dataset.label || ""; // Dataset label
                  const value = tooltipItem.raw; // Bar value

                  const legendDescription = isWalkIndex ? getLegendDescription(tooltipItem.raw) : '';
                  const additionalDescription = isWalkIndex && mapTab !== 'Comfort' ? getDescription(isWalkIndex, mapTab) : '';
                  const valueDescription = !isWalkIndex ? `${datasetLabel}: ${value}` : '';

                  return `${legendDescription} ${additionalDescription}${valueDescription}`;
                }
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              color: "#fff",
              grid: {
                display: false,
              },
              border: {
                color: "white",
              },
              ticks: {
                color: "#fff",
                font: {
                  size: 12,
                  family: "Montserrat",
                },
              },
              title: {
                display: true,
                text: "Addresses",
                color: "#fff",
                font: {
                  size: 12,
                  family: "Montserrat",
                },
              },
            },
            y: {
              stacked: true,
              border: {
                display: true,
                color: "white", // Axis border color
                dash: function (context) {
                  // Dynamically set dash pattern based on tick value
                  const tick = context.tick.value;
                  if (tick === 3) return []; // Solid line (no dash)
                  if (tick === 2) return [10, 5]; // Dashed line
                  if (tick === 1) return [3, 3]; // Dotted line
                  return []; // Default for other ticks (solid)
                },
              },
              ticks: {
                callback: function (value) {
                  if (value === 0 && isWalkIndex) {
                    return ""; // Don't show a label for value 0
                  }
                
                  const ticks = getYAxisTicks();
                  const maxValue = getMaxTickValue()
                  
                  return maxValue > 300 ? roundToNearest(value) : ticks.includes(value) ? value : "";
                },
                max: Math.max(...getYAxisTicks()), // Use the maximum value from the ticks
                min: 0, // Ensure the minimum tick is 0
                stepSize: function () {
                  const ticks = getYAxisTicks();
                  return ticks[0] > 100 || isWalkIndex ? ticks[1] - ticks[2] || ticks[1] : 5
                }(),
                color: "#fff",
                font: {
                  size: 14,
                  family: "Montserrat",
                },
              },
              max: Math.max(...getYAxisTicks()),
              min: 0,
              title: {
                display: false,
              },
              grid: {
                display: isWalkIndex ? true : false,
                drawBorder: true,
                drawTicks: true,
                drawOnChartArea: true,
                z: 1,
                color: function (context) {
                  const tick = context.tick.value;
                  if (tick === 3) return "#ffffff";
                  if (tick === 2) return "#ffffff";
                  if (tick === 1) return "#ffffff";
                  // if (tick === 1) return findColor('walkindex', mapTab);
                  return "transparent"; // Hide other grid lines
                },
                lineWidth: function (context) {
                  const tick = context.tick.value;
                  if ([3, 2, 1].includes(tick)) return 2; // Thicker line for styled ticks
                  return 0; // No line for other ticks
                },
              },
            },
          },
          onClick: (event, activeElements) => {
            if (activeElements.length > 0) {
              const chart = chartInstance.current; // Reference to the chart instance
              const datasetIndex = activeElements[0].datasetIndex; // Dataset index
              const dataIndex = activeElements[0].index; // Data point index
      
              // Access the clicked data
              const clickedLabel = chart.data.labels[dataIndex]; // X-axis label
              const clickedValue = chart.data.datasets[datasetIndex].data[dataIndex]; // Data value
              const clickedDataset = chart.data.datasets[datasetIndex].label; // Dataset label
      
              // Perform your custom action
              console.log(`Address: ${clickedLabel}`);
              console.log(`Dataset: ${clickedDataset}`);
              console.log(`Value: ${clickedValue}`);
              handleShowEstablishments(dataIndex, clickedDataset)
            }
          },
        },
        plugins: [
          {
            id: "addText",
            afterDatasetsDraw: (chart) => {
              const ctx = chart.ctx;
              const datasets = chart.data.datasets;
        
              if (!isWalkIndex) {
                datasets.forEach((dataset, datasetIndex) => {
                  if (datasets.length - 1 === datasetIndex) {
                    dataset.data.forEach((_, index) => {
                      let accumulatedValue = 0;
          
                      // Calculate the sum of values for this index across all datasets
                      datasets.forEach((innerDataset) => {
                        accumulatedValue += innerDataset.data[index] || 0;
                      });
          
                      // Get bar details for the current index
                      const meta = chart.getDatasetMeta(datasetIndex);
                      const bar = meta.data[index];
          
                      if (bar && accumulatedValue > 0) {
                        const x = bar.x; // X position of the bar
                        const y = bar.y; // Y position (top of the bar)
          
                        ctx.save();
                        ctx.font = "bold 14px Montserrat";
                        ctx.fillStyle = "#fff"; // Set text color
                        ctx.textAlign = "center"; // Center align the text
                        ctx.fillText(`${accumulatedValue > 0 ? accumulatedValue : ""}`, x, y - 8); // Draw the accumulated value above the bar
                        ctx.restore();
                      }
                    });
                  }
                });
              }
            },
          },
        ]
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [lifeStyleCompareMaps, lifeStyleScores, walkindexScores]);

  const getYAxisTicks = () => {
    if (isWalkIndex) {
      return [3, 2, 1, 0];
    }
  
    // Get the maximum tick value
    const maxValue = getMaxTickValue();
  
    // Dynamically calculate ticks
    let secondTick, thirdTick;
  
    if (maxValue > 100) {
      // For values above 100, use rounding to nearest 50
      secondTick = Math.ceil((maxValue / 2) / 50) * 50;
      thirdTick = Math.ceil((secondTick / 2) / 50) * 50;
    } else if (maxValue <= 100 && maxValue > 50) {
      // For values between 50 and 100, use rounding to nearest 10
      secondTick = Math.ceil(maxValue / 2 / 10) * 10;
      thirdTick = Math.ceil(secondTick / 2 / 10) * 10;
    } else {
      // For values below 50, calculate ticks directly
      secondTick = Math.floor(maxValue / 2); // Half of maxValue
      thirdTick = Math.floor(secondTick / 2); // Half of secondTick
    }
  
    // Ensure the ticks are meaningful and properly ordered
    return [maxValue, secondTick, thirdTick, 0];
  };

  const getMaxTickValue = () => {
    const filteredScores = isAll
    ? lifeStyleScores
    : lifeStyleScores.map((item) => {
      const normalizedKey = mapTab.toLowerCase();
      return normalizedKey in item ? { [normalizedKey]: item[normalizedKey] } : {};
    });

    const sums = filteredScores.map((item) =>
      Object.values(item).reduce((sum, value) => sum + value, 0)
    );

    const maxSum = Math.max(...sums);

    return Math.ceil(maxSum / 50) * 50;
  };

  const roundToNearest = (value) => {
    // Determine the nearest rounding factor based on maxValue
    const maxValue = getMaxTickValue()
    let roundingFactor;
    if (maxValue > 1000) {
      roundingFactor = 500; // For large values, round to nearest 500
    } else if (maxValue > 500) {
      roundingFactor = 100; // For medium values, round to nearest 100
    } else {
      roundingFactor = 50; // For smaller values, round to nearest 50
    }
    // Perform the rounding
    return Math.ceil(value / roundingFactor) * roundingFactor;
  };

  const getScoresByFeature = (feature, addressIndex) => {
    if (!compareLifeStyleAddress[addressIndex]?.property) {
      // If the address at this index does not exist or is invalid, return 0
      return 0;
    }
    return lifeStyleScores[addressIndex]?.[feature.toLowerCase()] ?? 0;
  };

  const generateDatasets = () => {
    if (isWalkIndex) {
      // Handling Walk Index scenario
      const feature = walkIndexFeatures.find((feature) => feature.name === mapTab);
      return [
        {
          label: mapTab,
          data: compareLifeStyleAddress.map((address, addressIndex) => {
            if (!feature || !walkindexScores[addressIndex]) {
              return 0;
            }
            const scores = walkindexScores[addressIndex];
            return Object.keys(scores && address || {}).length === 0 ? 0 : getSoloFeelScore(scores?.[feature.id]);
          }),
          backgroundColor: feature ? `rgb(${hexToRgb(feature.color)})` : 'rgba(0, 0, 0, 0.1)',
        },
      ];
    } else {
      // Handling non-Walk Index scenario
      const featuresToMap = isAll
        ? [...filteredGemFeatures].reverse()
        : filteredGemFeatures.filter((feature) => feature.name === mapTab);
  
      return featuresToMap.map((feature) => ({
        label: feature.name,
        data: compareLifeStyleAddress.map((_, addressIndex) =>
          getScoresByFeature(feature.id, addressIndex)
        ),
        backgroundColor: `rgb(${hexToRgb(feature.color)})`,
      }));
    }
  };

  const getLegendDescription = (index) => {
    const scores = index;
    const finalType = mapTab?.toLowerCase() === 'vibrance' ? 'activities' : mapTab?.toLowerCase();
  
    const getDescription = (descriptionSource) => {
      return descriptionSource?.[finalType]?.[(scores || 1) - 1];
    };
  
    return getDescription(legendWalkIndexDescription)
  };

  const renderVersion2FeelLegend = () => {
    return (
      <div className={`flex flex-col w-full ${
          isMobile ? 'mt-0' : 'mt-2'
        }`}
      >
        {[3, 2, 1].map((index) => (
          <div
            key={index}
            className="map-type-legend flex justify-start sm:justify-between items-center gap-x-3 text-center text-xs font-normal"
            data-border-style={index}
            style={getTabColor(isWalkIndex, mapTab)}
          >
            {getFeelLineLegend2(index)}
            {getLegendDescription(index)}
          </div>
        ))}
      </div>
    )
  };

  const handleShowEstablishments = (index, tab) => {
    dispatch({ type: 'SET_SHOW_MARKER_LIST', payload: true });
    dispatch({ type: 'SET_SELECTED_MAP', payload: index });
    dispatch({ type: 'SET_MAP_TAB', payload: tab });
  };

  const renderChartLegends = () => {
    if (isWalkIndex) {

    } else {
      return isAll ? (
        <div className="flex flex-col space-y-2">
          {filteredLifeStyleFeatures.map((feature) => (
            <div key={feature.id} className="flex items-center">
              <span
                className="w-2 h-2"
                style={{ backgroundColor: feature.color }}
              ></span>
              <span className="ml-2 text-white text-xs">{feature.name}</span>
            </div>
          ))}
        </div>
      ) : <></>
    }
  };

  return (
    <div className={`graph-wrapper py-4 px-4 ${showFilter || showMarkerList ? 'hide-graph' : ''}`}>
      <h2 className='flex justify-between gap-x-6 font-bold'>
        <Show>
          <Show.When isTrue={isMobile && showCompareGraph}>
            <div className='flex gap-x-2 chart-legend w-fit text-sm' style={getTabColor(isWalkIndex, mapTab)}>
              {getFeatureIcon(isWalkIndex, mapTab)}
              {mapTab}
            </div>
          </Show.When>
          <Show.Else>
            {isWalkIndex ? 'Character' : 'Gem'} Score
          </Show.Else>
        </Show>
        <Show.When isTrue={!isAll}>
          <div className='flex flex-col items-end'>
            <Show.When isTrue={!isMobile}>
              <div className='flex gap-x-2 items-center chart-legend w-fit text-sm' style={getTabColor(isWalkIndex, mapTab)}>
                {getFeatureIcon(isWalkIndex, mapTab)}
                {mapTab}
              </div>
            </Show.When>
            <Show.When isTrue={isWalkIndex}>
              {renderVersion2FeelLegend()}
            </Show.When>
          </div>
        </Show.When>
      </h2>
      <div>
        <div
          className={`flex justify-between items-end mb-2 ${
            mapTab === 'All' ? '-mt-8' : 'mt-0'
          } ${
            isMobile && !isWalkIndex ? '-mt-4' : ''
          }`}
        >
          <div className="w-[130px] text-xs mb-2">{isWalkIndex ? getDescription(isWalkIndex, mapTab) : 'Gems' }</div>
          {renderChartLegends()}
        </div>
        <canvas ref={chartRef} width="100%" height={isMobile ? "100%" : "97.5%"}/>
      </div>
    </div>
  )
}

export default CompareGraph;