import React, { useContext } from "react";
import { StoreContext } from "../../store";

import Hotel from '../../assets/landing-page/travel/hotel.png';
import PoI from '../../assets/landing-page/travel/poi.png';
import Owners from '../../assets/landing-page/travel/by-owners.png';
import Shopping from '../../assets/landing-page/travel/shopping.png';
import Foods from '../../assets/landing-page/travel/foods.png';
import Transits from '../../assets/landing-page/travel/transits.png';
import CNH1 from '../../assets/landing-page/chn1.png';
import CNH2 from '../../assets/landing-page/chn2.png';
import MobileSim1 from '../../assets/landing-page/travel/mobile-sim.png';
import MobileSim2 from '../../assets/landing-page/travel/mobile-sim2.png';
import MobileSim3 from '../../assets/landing-page/travel/mobile-sim3.svg';
import MobileSim4 from '../../assets/landing-page/travel/mobile-sim4.png';
import MobileSim5 from '../../assets/landing-page/travel/mobile-sim5.svg';
import CSV from '../../assets/landing-page/travel/csv.png';
import CSV2 from '../../assets/landing-page/travel/csv2.png';
import { ReactComponent as NewArrow } from '../../assets/landing-page/travel/new-arrow-icon.svg';
import Show from '../../utility/Show';

import { scrollToTop } from "../../services";

const travelData = [
  {
    id: 1,
    title: "Hotels",
    image: Hotel
  },
  {
    id: 2,
    title: "Points of Interest",
    image: PoI,
  },
  {
    id: 3,
    title: "By Owners",
    image: Owners,
  },
  {
    id: 4,
    title: "Shopping",
    image: Shopping,
  },
  {
    id: 5,
    title: "Food",
    image: Foods,
  },
  {
    id: 6,
    title: "Transit",
    image: Transits,
  },
];

const preferentialData = [
  {
    id: 1,
    title: "WALKING MAP DETAIL",
    description:
      "A walking map detail to display the character and quality of the walking environment using Walkspan’s proprietary sidewalk scoring system. The walking map detail will lay out sidewalk scores based on the absence and presence of walkability features.",
    note: "(In-progress)",
    googleImage: MobileSim1,
    walkspanImage: MobileSim2,
  },
  {
    id: 2,
    title: "PREFERENTIAL MAPPING SYSTEM",
    description:
      "A proprietary Preferential Mapping System designed to help individuals find their preferred walking routes. Walkability values are assigned to each sidewalk segment to help identify the route with the highest score. The navigation algorithm displays short distance alongside the preferred route on the map.",
    note: "(In-progress)",
    googleImage: MobileSim3,
    walkspanImage: MobileSim4,
  },
  {
    id: 3,
    title: "CONTINOUS SIDEWALK VIEW",
    description:
      "Unlike Google Streets, Walkspan offers more than a collection of sidewalk views. Walkspan’s technology provides a proprietary computer vision image detection system capable of facilitating the automatic and simultaneous gathering, scoring, mapping, reporting of conditions and recommendation of solutions.",
    note: "(In-progress)",
    googleImage: CSV,
    walkspanImage: CSV2,
  },
];

const Header = () => {
  const { navigate } = useContext(StoreContext);

  const gotoContactUs = () => {
    scrollToTop();
    navigate('/contact-us');
    document.title = 'Contact Us - Walkspan';
  };

  return (
    <div className="bg-black py-16 text-white">
      <div className="text-center max-w-3xl mx-auto">
        <p className="text-lg px-4">
          <b>Explore</b> hotel neighborhood for nearby transit, restaurants, shopping, and
          <br/> points of interest.
        </p>
      </div>

      <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-4 mt-12">
        {travelData.map((item) => (
          <div
            key={item.id}
            className="relative group overflow-hidden shadow-lg"
          >
            <img
              src={item.image}
              alt={item.title}
              className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 flex items-center justify-center transition-opacity duration-300">
              <h3 className="text-xl font-medium text-white uppercase">{item.title}</h3>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center mt-8">
          <button className="landing-view-btn" onClick={() => gotoContactUs()}>
            Request a Demo
          </button>
      </div>
    </div>
  );
};

const PreferentialNavigation = () => {
  return (
    <div className="bg-black py-16 text-white travel-gradient">
      <h2 className="text-center lg:text-3xl text-xl font-medium mb-12 uppercase">
        Preferential Navigation System
      </h2>
      <div className="max-w-6xl mx-auto space-y-16 px-4">
      {preferentialData.map((item, index) => (
        <div
          key={item.id}
          className={`flex ${
            index !== 1 ? "flex-col md:flex-row" : "flex-col"
          } items-center md:items-start gap-8`}
        >
          {/* Text Section */}
          <div className={`flex flex-col space-y-4 mt-8 ${index === 1 ? 'w-full' : 'md:w-1/2'}`}>
            <h3 className="lg:text-5xl 2xs:text-2xl font-bold max-w-[350px]">{`0${item.id}`}</h3>
            <h4 className={`lg:text-4xl 2xs:text-2xl font-medium ${index === 1 ? '' : 'max-w-[350px]'}`}>{item.title}</h4>
            <p className="leading-relaxed">{item.description}</p>
            <span className="2xs:text-sm text-center lg:text-left">{item.note}</span>
          </div>

          {/* Image Section */}

          <div
            className={`${
              index === 1 ? "w-full grid-cols-3" : "md:w-1/2 grid-cols-2"
            } grid  gap-4 items-center ${
              item.id === 3 ? "!flex flex-col" : ""
            }`}
          >
            {index === 1 && (
              <div>
                <p className="text-center mb-2 text-sm">with Walkspan</p>
                <img
                  src={MobileSim5}
                  alt={`Google ${item.title}`}
                  className="w-full rounded-lg shadow-lg"
                />
              </div>
            )}

            <div>
              <p className={`text-center mb-2 text-sm ${
                  index !== 0 ? 'invisible' : ''
                }`}
              >
                Google
              </p>
              <Show.When isTrue={index === 2}>
                <p className="text-xl text-center mb-2">Continuous Street View</p>
              </Show.When>
              <img
                src={item.googleImage}
                alt={`Google ${item.title}`}
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            <div className={`${index === 0 ? 'relative' : ''}`}>
              <p className='text-center mb-2 text-sm invisible'>with Walkspan</p>
              <Show.When isTrue={index === 2}>
                <p className="text-xl text-center">Continuous Sidewalk View</p>
                <p className="text-md text-gray-300 text-center mb-2">(with Walkspan)</p>
              </Show.When>
              <img
                src={item.walkspanImage}
                alt={`Walkspan ${item.title}`}
                className="w-full rounded-lg shadow-lg"
              />
              <Show.When isTrue={index === 0}>
                <NewArrow className="absolute w-auto 2xs:h-[24px] md:h-[30px] 2xs:bottom-7 2xs:-right-5 md:bottom-10 md:-right-5"/>
              </Show.When>
            </div>
            <p className="text-center text-md col-span-3">
              for simulation purposes only
            </p>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

const Travel = () => {
  return (
    <div className='bg-white flex flex-col'>
        {Header()}
        <div className="flex flex-col gap-y-8 text-center p-4 lg:p-12 max-w-screen-xl mx-auto">
          <span className='text-2xl 2xs:text-xl uppercase'><b>Compare</b> Hotel Neighborhoods</span>
          <img
            src={CNH1}
            alt="Neighborhood Gems"
            className="w-full rounded"
          />
          <img
            src={CNH2}
            alt="Neighborhood Gems"
            className="w-full"
          />
        </div>
        {PreferentialNavigation()}
    </div>
  )
}

export default Travel;
